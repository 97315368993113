import { FC, useCallback } from "react";
import { Switch, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDataEntryObjectInputParameterExclusion } from "@netcero/netcero-core-api-client";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { DataEntryObjectInputParameterExclusionDialog } from "../../data-entry-objects-input-parameters/dialogs/data-entry-object-input-parameter-exclusion.dialog";

interface IDrEggExcludeInputProps {
  isEligibleForPhaseIn: boolean;
  value: IDataEntryObjectInputParameterExclusion | undefined;
  onChange: (value: IDataEntryObjectInputParameterExclusion | undefined) => void;
  disabled?: boolean;
}

export const DrEggExcludeInput: FC<IDrEggExcludeInputProps> = ({
  isEligibleForPhaseIn,
  value,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("dr_egg_overview");

  const { isOpen: showExclusionDialog, openDialog, closeDialog } = useDialogStateWithoutData();

  const handleExclude = useCallback(
    (data: IDataEntryObjectInputParameterExclusion) => {
      onChange(data);
      closeDialog();
    },
    [closeDialog, onChange],
  );

  const handleInclude = useCallback(() => {
    onChange(undefined);
    closeDialog();
  }, [closeDialog, onChange]);

  const handleChangeExclusionState = useCallback(() => openDialog(), [openDialog]);

  return (
    <>
      <DataEntryObjectInputParameterExclusionDialog
        open={showExclusionDialog}
        onClose={closeDialog}
        value={value}
        isEligibleForPhaseIn={isEligibleForPhaseIn}
        onExclude={handleExclude}
        onInclude={handleInclude}
      />

      {/* Switch */}
      <Tooltip title={t("labels.includeInReport")}>
        <span>
          <Switch
            size="small"
            checked={!value}
            onChange={handleChangeExclusionState}
            disabled={disabled}
          />
        </span>
      </Tooltip>
    </>
  );
};
