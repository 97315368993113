import { Breadcrumbs, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { IDMACategoryWithEffectsAndChildren, IESRSTopic } from "@netcero/netcero-core-api-client";
import { DMAUtilities } from "../utilities/dma.utilities";
import { useRenderDMACategoryName } from "../hooks/render-dma-category-name.hook";
import { useRenderESRSTopicName } from "../hooks/render-esrs-topic-name.hook";

interface IDMABreadcrumbsComponentProps {
  esrsTopic: IESRSTopic | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
}

export const DMABreadcrumbsComponent: FC<IDMABreadcrumbsComponentProps> = ({
  esrsTopic,
  dmaCategory,
}) => {
  const renderName = useRenderDMACategoryName();
  const renderTopicName = useRenderESRSTopicName();
  const pathToChild = useMemo(() => {
    // Check if category and topic are defined
    if (dmaCategory === null || esrsTopic === null) {
      // Fallback to empty array if either is not defined
      return [];
    }

    // Use the utility function to find parents
    return DMAUtilities.findParentsOfTopic(esrsTopic, dmaCategory.id) ?? []; // Ensure fallback to empty array if null
  }, [dmaCategory, esrsTopic]);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: 1.5 }} maxItems={2}>
      <Typography variant="inherit">{`${esrsTopic?.topic} - ${renderTopicName(
        esrsTopic,
      )}`}</Typography>
      {pathToChild &&
        pathToChild.map((element, index) => {
          return (
            <Typography
              key={index}
              variant="inherit"
              color={index === pathToChild.length - 1 ? "text.primary" : undefined}
              sx={{ wordWrap: "break-word" }}
            >
              {renderName(element)}
            </Typography>
          );
        })}
    </Breadcrumbs>
  );
};
