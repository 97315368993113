/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IDataEntryObjectBase } from "../models";
// @ts-ignore
import { IDataEntryObjectData } from "../models";
// @ts-ignore
import { IGetDataEntryObject404Response } from "../models";
// @ts-ignore
import { IOrganizationStructureDetailed } from "../models";
// @ts-ignore
import { IUpdateDataEntryObject400Response } from "../models";
/**
 * DataEntryObjectApi - axios parameter creator
 * @export
 */
export const DataEntryObjectApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns all the details for a data entry object.
     * @summary Get data entry object.
     * @param {string} organizationId The id of the organization the data entry belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataEntryObject: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getDataEntryObject", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getDataEntryObject", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("getDataEntryObject", "dataEntryObjectId", dataEntryObjectId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates basic properties (not affecting the tree structure of the organization structure) of the data entry object.
     * @summary Update data entry object.
     * @param {string} organizationId The id of the organization the data entry object is part of.
     * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to update.
     * @param {IDataEntryObjectBase} [iDataEntryObjectBase]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObject: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      iDataEntryObjectBase?: IDataEntryObjectBase,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateDataEntryObject", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateDataEntryObject", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("updateDataEntryObject", "dataEntryObjectId", dataEntryObjectId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDataEntryObjectBase,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataEntryObjectApi - functional programming interface
 * @export
 */
export const DataEntryObjectApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DataEntryObjectApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns all the details for a data entry object.
     * @summary Get data entry object.
     * @param {string} organizationId The id of the organization the data entry belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataEntryObject(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDataEntryObjectData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataEntryObject(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates basic properties (not affecting the tree structure of the organization structure) of the data entry object.
     * @summary Update data entry object.
     * @param {string} organizationId The id of the organization the data entry object is part of.
     * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to update.
     * @param {IDataEntryObjectBase} [iDataEntryObjectBase]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDataEntryObject(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      iDataEntryObjectBase?: IDataEntryObjectBase,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizationStructureDetailed>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataEntryObject(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        iDataEntryObjectBase,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DataEntryObjectApi - factory interface
 * @export
 */
export const DataEntryObjectApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataEntryObjectApiFp(configuration);
  return {
    /**
     * Returns all the details for a data entry object.
     * @summary Get data entry object.
     * @param {string} organizationId The id of the organization the data entry belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataEntryObject(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: any,
    ): AxiosPromise<IDataEntryObjectData> {
      return localVarFp
        .getDataEntryObject(organizationId, recordingPeriodId, dataEntryObjectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates basic properties (not affecting the tree structure of the organization structure) of the data entry object.
     * @summary Update data entry object.
     * @param {string} organizationId The id of the organization the data entry object is part of.
     * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to update.
     * @param {IDataEntryObjectBase} [iDataEntryObjectBase]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObject(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      iDataEntryObjectBase?: IDataEntryObjectBase,
      options?: any,
    ): AxiosPromise<IOrganizationStructureDetailed> {
      return localVarFp
        .updateDataEntryObject(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          iDataEntryObjectBase,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DataEntryObjectApi - interface
 * @export
 * @interface DataEntryObjectApi
 */
export interface DataEntryObjectApiInterface {
  /**
   * Returns all the details for a data entry object.
   * @summary Get data entry object.
   * @param {string} organizationId The id of the organization the data entry belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to get.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectApiInterface
   */
  getDataEntryObject(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDataEntryObjectData>;

  /**
   * Updates basic properties (not affecting the tree structure of the organization structure) of the data entry object.
   * @summary Update data entry object.
   * @param {string} organizationId The id of the organization the data entry object is part of.
   * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to update.
   * @param {IDataEntryObjectBase} [iDataEntryObjectBase]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectApiInterface
   */
  updateDataEntryObject(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    iDataEntryObjectBase?: IDataEntryObjectBase,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IOrganizationStructureDetailed>;
}

/**
 * DataEntryObjectApi - object-oriented interface
 * @export
 * @class DataEntryObjectApi
 * @extends {BaseAPI}
 */
export class DataEntryObjectApi extends BaseAPI implements DataEntryObjectApiInterface {
  /**
   * Returns all the details for a data entry object.
   * @summary Get data entry object.
   * @param {string} organizationId The id of the organization the data entry belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to get.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectApi
   */
  public getDataEntryObject(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectApiFp(this.configuration)
      .getDataEntryObject(organizationId, recordingPeriodId, dataEntryObjectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates basic properties (not affecting the tree structure of the organization structure) of the data entry object.
   * @summary Update data entry object.
   * @param {string} organizationId The id of the organization the data entry object is part of.
   * @param {string} recordingPeriodId The id of the recording period the data entry belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to update.
   * @param {IDataEntryObjectBase} [iDataEntryObjectBase]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectApi
   */
  public updateDataEntryObject(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    iDataEntryObjectBase?: IDataEntryObjectBase,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectApiFp(this.configuration)
      .updateDataEntryObject(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        iDataEntryObjectBase,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
