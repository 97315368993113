import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  FormGroup,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IFilterIROs,
  IIROsOverviewIROClickHandler,
  IIROsOverviewIROClickHandlerData,
  IROsOverviewList,
} from "../../../double-materiality-assessment/impacts-risks-opportunities/components/iros-overview-list.component";
import {
  IDataEntryObject,
  IDMAFinancialEffectWithCalculatedValues,
  IDMAMaterialImpactWithCalculatedValues,
  IESRSTopicIdentifier,
  IInputParameterRecordingStructureGroupESRSDisclosureRequirementFillInHelperIROListType,
} from "@netcero/netcero-core-api-client";
import { IIrosListEntry } from "../../../double-materiality-assessment/impacts-risks-opportunities/impacts-risks-opportunitites.types";
import { QueryWrapper } from "../../../common/components/query-wrapper.component";
import {
  useDMAConfigurationQuery,
  useESRSTopicsQuery,
} from "../../../double-materiality-assessment/dma.queries";
import { useTranslation } from "react-i18next";
import { DMAMaterialImpactEditDialog } from "../../../double-materiality-assessment/material-impacts/dma-material-impact-edit.dialog";
import { useDialogState } from "../../../common/dialogs/dialog-state.hook";
import { DMAFinancialEffectEditDialog } from "../../../double-materiality-assessment/financial-effects/dma-financial-effect-edit.dialog";
import { FilterIcon, InfoIcon } from "../../../common/constants/tabler-icon.constants";
import { CenteredCircularProgress } from "../../../common/components/centered-circular-progress.component";
import { IRO_TYPES, IroType } from "@netcero/netcero-common";
import { IROsESRSTopicsTabs } from "./iros-esrs-topics-tabs.component";

type IFillInHelperIrosListType = Exclude<
  IInputParameterRecordingStructureGroupESRSDisclosureRequirementFillInHelperIROListType,
  "none"
>;

interface IFillInHelperIrosListProps {
  type: IFillInHelperIrosListType;
  esrsTopic: IESRSTopicIdentifier;
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObject: IDataEntryObject;
  dataEntryObjectId: string;
}

const FILTER_FOR_TYPE: Record<IFillInHelperIrosListType, IFilterIROs> = {
  "only-material": (iro) => iro.isMaterial,
  "topic-specific": (iro) => true,
};

/**
 * Component that displays a list of IROs based on the type of the FillInHelperIROsList.
 * @param type The type of the FillInHelperIROsList
 * @param esrsTopic Depending on the mode either the topic that is initially used for the topic filter or the only topic for which IROs are displayed.
 * @param organizationId
 * @param recordingPeriodId
 * @param rootDataEntryObject
 * @param dataEntryObjectId
 * @constructor
 */
export const FillInHelperIrosList: FC<IFillInHelperIrosListProps> = ({
  type,
  esrsTopic,
  organizationId,
  recordingPeriodId,
  rootDataEntryObject,
  dataEntryObjectId,
}) => {
  const { t } = useTranslation("fill_in_helper_iros_list_component");
  const esrsTopicsQuery = useESRSTopicsQuery(organizationId, recordingPeriodId, dataEntryObjectId);
  const dmaConfigurationQuery = useDMAConfigurationQuery(organizationId, recordingPeriodId);

  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const [filterEsrsTopic, setFilterEsrsTopic] = useState<IESRSTopicIdentifier>(esrsTopic);
  useEffect(() => {
    setFilterEsrsTopic(esrsTopic);
  }, [esrsTopic]);

  const typeFilterButtonRef = useRef<HTMLButtonElement>(null);
  const [filterType, setFilterType] = useState<IroType[]>([]);

  const defaultFilterIROs: IFilterIROs = useMemo(() => FILTER_FOR_TYPE[type], [type]);
  const filterIROs: IFilterIROs = useCallback(
    (iro: IIrosListEntry) =>
      defaultFilterIROs(iro) &&
      iro.topicIdentifier === filterEsrsTopic &&
      (filterType.length === 0 || filterType.includes(iro.type)),
    [filterEsrsTopic, filterType, defaultFilterIROs],
  );

  const {
    isOpen: showMaterialImpactDialog,
    openDialog: openMaterialImpactDialog,
    closeDialog: closeMaterialImpactDialog,
    data: showMaterialImpact,
  } = useDialogState<IDMAMaterialImpactWithCalculatedValues>();
  const {
    isOpen: showFinancialEffectDialog,
    openDialog: openFinancialEffectDialog,
    closeDialog: closeFinancialEffectDialog,
    data: showFinancialEffect,
  } = useDialogState<IDMAFinancialEffectWithCalculatedValues>();

  const handleClickIRO: IIROsOverviewIROClickHandler = useCallback(
    (data: IIROsOverviewIROClickHandlerData) => {
      if (data.type === "materialImpact") {
        openMaterialImpactDialog(data.materialImpact);
      } else {
        openFinancialEffectDialog(data.financialEffect);
      }
    },
    [openFinancialEffectDialog, openMaterialImpactDialog],
  );

  // topic specific does NOT allow for manually filtering
  const showTopicFilter = type !== "topic-specific";

  return (
    <>
      {/* Type Menu */}
      <Menu
        open={showFilterMenu}
        onClose={() => setShowFilterMenu(false)}
        anchorEl={typeFilterButtonRef.current}
      >
        <FormGroup sx={{ px: 2 }}>
          {IRO_TYPES.map((type) => (
            <FormControlLabel
              key={type}
              control={
                <Checkbox
                  checked={filterType.includes(type)}
                  onChange={(_, checked) => {
                    if (checked) {
                      setFilterType((prev) => [...prev, type]);
                    } else {
                      setFilterType((prev) => prev.filter((id) => id !== type));
                    }
                  }}
                />
              }
              label={t(`iro_types.${type}`, { ns: "iros_overview_list_component" })}
            />
          ))}
        </FormGroup>
      </Menu>

      {/* Content */}
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          {/* Helper Title */}
          <Typography variant="subtitle1">{t("title")}</Typography>
          {/* Helper Info */}
          <Tooltip title={t(`type_hints.${type}`)} placement="right">
            <InfoIcon />
          </Tooltip>
          {/* Type Filter Button */}
          <Badge color="primary" badgeContent={filterType.length} sx={{ ml: "auto" }}>
            <Button
              ref={typeFilterButtonRef}
              onClick={() => setShowFilterMenu(true)}
              sx={{ gap: 1 }}
            >
              <FilterIcon />
              {t("filter_type")}
            </Button>
          </Badge>
        </Box>

        {/* DMA Configuration */}
        <QueryWrapper
          query={dmaConfigurationQuery}
          build={(dmaConfiguration) =>
            dmaConfiguration.type !== "exists" ? (
              <Typography textAlign="center">
                {t("notice_no_iros_at_all", { ns: "iros_overview_list_component" })}
              </Typography>
            ) : (
              <QueryWrapper
                query={esrsTopicsQuery}
                loadingOverride={() => <CenteredCircularProgress />}
                build={(response) => (
                  <>
                    {dmaConfiguration.type === "exists" && (
                      <>
                        {/* Edit Dialogs (read only) */}
                        <DMAMaterialImpactEditDialog
                          organizationId={organizationId}
                          dmaConfiguration={dmaConfiguration.configuration}
                          materialImpact={showMaterialImpact}
                          open={showMaterialImpactDialog}
                          esrsTopic={
                            response.esrsTopics.find(
                              (topic) => topic.identifier === filterEsrsTopic,
                            ) ?? null
                          }
                          dmaCategory={null}
                          dataEntryObject={rootDataEntryObject}
                          onClose={() => closeMaterialImpactDialog()}
                          readOnly
                        />
                        <DMAFinancialEffectEditDialog
                          open={showFinancialEffectDialog}
                          organizationId={organizationId}
                          recordingPeriodId={recordingPeriodId}
                          dmaCategory={null}
                          dmaConfiguration={dmaConfiguration.configuration}
                          financialEffect={showFinancialEffect}
                          dataEntryObject={rootDataEntryObject}
                          onClose={() => closeFinancialEffectDialog()}
                          esrsTopic={
                            response.esrsTopics.find(
                              (topic) => topic.identifier === filterEsrsTopic,
                            ) ?? null
                          }
                          readOnly
                        />
                      </>
                    )}

                    {/* ESRS Topics Dropdown */}
                    {showTopicFilter && (
                      <IROsESRSTopicsTabs
                        esrsTopics={response.esrsTopics}
                        selectedTopic={filterEsrsTopic}
                        onSelectTopic={setFilterEsrsTopic}
                      />
                    )}
                    <Fade key={filterEsrsTopic} in appear>
                      <Box>
                        <IROsOverviewList
                          esrsTopics={response.esrsTopics}
                          filterIROs={filterIROs}
                          onClick={handleClickIRO}
                        />
                      </Box>
                    </Fade>
                  </>
                )}
              />
            )
          }
        />
      </Box>
    </>
  );
};
