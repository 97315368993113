/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * esrs:GHGCategoryMember
 * @export
 * @enum {string}
 */

export const IGreenhouseGasesCoveredByEmissionReductionTargetEnum = {
  PollutantCarbonDioxideCo2Member: "esrs:PollutantCarbonDioxideCO2Member",
  PollutantMethaneCh4Member: "esrs:PollutantMethaneCH4Member",
  PollutantNitrousOxideN2OMember: "esrs:PollutantNitrousOxideN2OMember",
  HydrofluorocarbonsHfcsMember: "esrs:HydrofluorocarbonsHFCsMember",
  PerfluorocarbonsPfcsMember: "esrs:PerfluorocarbonsPFCsMember",
  PollutantSulphurHexafluorideSf6Member: "esrs:PollutantSulphurHexafluorideSF6Member",
  NitrogenTrifluorideNf3Member: "esrs:NitrogenTrifluorideNF3Member",
  OtherGhgCategoryMember: "esrs:OtherGHGCategoryMember",
} as const;

export type IGreenhouseGasesCoveredByEmissionReductionTargetEnum =
  (typeof IGreenhouseGasesCoveredByEmissionReductionTargetEnum)[keyof typeof IGreenhouseGasesCoveredByEmissionReductionTargetEnum];
