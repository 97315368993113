import { ModulesEnum } from "./modules.enum";
import { useMemo } from "react";
import { useKeycloakOrganizationData } from "../user/hooks/keycloak-organization-data.hook";

export const useHasOrganizationModuleAccess = (organizationId: string, module: ModulesEnum) => {
  const keycloakOrganizationData = useKeycloakOrganizationData(organizationId);

  return useMemo(() => {
    if (!keycloakOrganizationData) {
      return false;
    }
    const orgAttributes = keycloakOrganizationData.attributes;

    // Always grant access when full_access is set
    if (orgAttributes.full_access?.[0] === "true") {
      return true;
    }
    // DMA
    if (module === ModulesEnum.DMA) {
      return orgAttributes.dma_access?.[0] === "true";
    }
    // ESRS
    if (module === ModulesEnum.ESRS) {
      return orgAttributes.esrs_access?.[0] === "true";
    }
  }, [keycloakOrganizationData, module]);
};
