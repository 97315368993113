/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from "../models";
// @ts-ignore
import { IGetAllInputParameterValueListValuesResponse } from "../models";
/**
 * InputParameterValueListValuesApi - axios parameter creator
 * @export
 */
export const InputParameterValueListValuesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Returns all Input Parameter Value List Values for an organization, grouped by the type.
     * @summary Get all Input Parameter Value List Values for an organization, grouped by the type.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInputParameterValueListValues: async (
      organizationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getAllInputParameterValueListValues", "organizationId", organizationId);
      const localVarPath =
        `/organizations/{organizationId}/input-parameter-value-list-values`.replace(
          `{${"organizationId"}}`,
          encodeURIComponent(String(organizationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InputParameterValueListValuesApi - functional programming interface
 * @export
 */
export const InputParameterValueListValuesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InputParameterValueListValuesApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns all Input Parameter Value List Values for an organization, grouped by the type.
     * @summary Get all Input Parameter Value List Values for an organization, grouped by the type.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllInputParameterValueListValues(
      organizationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetAllInputParameterValueListValuesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInputParameterValueListValues(
        organizationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InputParameterValueListValuesApi - factory interface
 * @export
 */
export const InputParameterValueListValuesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InputParameterValueListValuesApiFp(configuration);
  return {
    /**
     * Returns all Input Parameter Value List Values for an organization, grouped by the type.
     * @summary Get all Input Parameter Value List Values for an organization, grouped by the type.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInputParameterValueListValues(
      organizationId: string,
      options?: any,
    ): AxiosPromise<IGetAllInputParameterValueListValuesResponse> {
      return localVarFp
        .getAllInputParameterValueListValues(organizationId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InputParameterValueListValuesApi - interface
 * @export
 * @interface InputParameterValueListValuesApi
 */
export interface InputParameterValueListValuesApiInterface {
  /**
   * Returns all Input Parameter Value List Values for an organization, grouped by the type.
   * @summary Get all Input Parameter Value List Values for an organization, grouped by the type.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterValueListValuesApiInterface
   */
  getAllInputParameterValueListValues(
    organizationId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetAllInputParameterValueListValuesResponse>;
}

/**
 * InputParameterValueListValuesApi - object-oriented interface
 * @export
 * @class InputParameterValueListValuesApi
 * @extends {BaseAPI}
 */
export class InputParameterValueListValuesApi
  extends BaseAPI
  implements InputParameterValueListValuesApiInterface
{
  /**
   * Returns all Input Parameter Value List Values for an organization, grouped by the type.
   * @summary Get all Input Parameter Value List Values for an organization, grouped by the type.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterValueListValuesApi
   */
  public getAllInputParameterValueListValues(organizationId: string, options?: AxiosRequestConfig) {
    return InputParameterValueListValuesApiFp(this.configuration)
      .getAllInputParameterValueListValues(organizationId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
