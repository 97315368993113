import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SvgIcon, TextField, Tooltip } from "@mui/material";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { IInternalEffectBaseData } from "./types/internal-effect-base-data.type";
import { useTranslation } from "react-i18next";

interface IIroCommonInputsProps {
  disabled: boolean;
}

export const IroCommonInputs: FC<IIroCommonInputsProps> = ({ disabled }) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");
  const { control } = useFormContext<IInternalEffectBaseData>();

  return (
    <>
      {/* Title */}
      <Controller
        control={control}
        name="title"
        rules={{
          required: t("error_title_required"),
          validate: (value) => {
            if (value.trim() === "") {
              return t("error_title_required");
            }

            return true;
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={t("label_title")}
            error={!!error}
            helperText={error?.message}
            fullWidth
            multiline
            maxRows={3}
            disabled={disabled}
          />
        )}
      />

      {/* Description */}
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={t("label_description")}
            error={!!error}
            helperText={error?.message}
            multiline
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Tooltip title={t("description_explanation")}>
                  <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              ),
            }}
          />
        )}
      />

      {/* Assessment Explanation */}
      <Controller
        control={control}
        name="assessmentExplanation"
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={t("label_assessmentExplanation")}
            error={!!error}
            helperText={error?.message}
            multiline
            minRows={3}
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Tooltip title={t("assessmentExplanation_explanation")}>
                  <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              ),
            }}
          />
        )}
      />

      {/* Internal communication */}
      <Controller
        control={control}
        name="internalCommunication"
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={t("label_internalCommunication")}
            error={!!error}
            helperText={error?.message}
            multiline
            minRows={3}
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {t("internalCommunication_info")}
                    </span>
                  }
                >
                  <SvgIcon fontSize="small" color="action" sx={{ mb: "auto" }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              ),
            }}
          />
        )}
      />
    </>
  );
};
