import { DMA_SEVERITY_MULTIPLIER } from "../constants/dma.constants";

export class DmaFormatUtilities {
  /**
   * Formats the given severity
   * @param severityAsFraction The fraction to format - has to be between 0 and 1!
   */
  public static formatSeverityValue(severityAsFraction: number) {
    return (severityAsFraction * DMA_SEVERITY_MULTIPLIER).toFixed(1);
  }

  /**
   * Formats the given materiality degree
   * @param materialityDegree The degree to format (value between 0 and 5)
   */
  public static formatMaterialityDegreeValue(materialityDegree: number) {
    return materialityDegree.toFixed(1);
  }
}
