import { Dispatch, FC, useMemo } from "react";
import { Chip, Skeleton, SvgIcon, Typography } from "@mui/material";
import { ISource } from "@netcero/netcero-core-api-client";
import { SourcesIcon } from "../../common/constants/tabler-icon.constants";
import { useSourcesQuery } from "../sources.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { createSourcesConfigurationPageLink } from "../../../pages/configuration/sources-configuration.page";

interface ISourceChipsCommonProps {
  organizationId: string;
  onDelete?: Dispatch<ISource>;
  disabled?: boolean;
}

interface ISourceChipProps extends ISourceChipsCommonProps {
  source: ISource;
}

export const SourceChip: FC<ISourceChipProps> = ({
  organizationId,
  source,
  onDelete,
  disabled,
}) => {
  return (
    <Chip
      icon={<SvgIcon component={SourcesIcon} fontSize="small" sx={{ fill: "transparent" }} />}
      label={source.name}
      onClick={() =>
        window
          .open(createSourcesConfigurationPageLink(organizationId, source.id), "_blank")
          ?.focus()
      }
      onDelete={
        onDelete && !disabled
          ? (evt) => {
              // Otherwise Link will be triggered
              evt.stopPropagation();
              onDelete(source);
            }
          : undefined
      }
      sx={{ pl: 0.5 }}
    />
  );
};

interface ISourceChipWithQueryProps extends ISourceChipsCommonProps {
  sourceId: string;
}

export const SourceChipWithQuery: FC<ISourceChipWithQueryProps> = ({
  sourceId,
  ...commonProps
}) => {
  const sourcesQuery = useSourcesQuery(commonProps.organizationId);

  const source = useMemo(
    () => sourcesQuery.data?.find((s) => s.id === sourceId) ?? null,
    [sourceId, sourcesQuery.data],
  );

  return (
    <QueryWrapper
      query={sourcesQuery}
      loadingOverride={() => <SourceChipSkeleton />}
      build={() =>
        source ? (
          <SourceChip {...commonProps} source={source} />
        ) : (
          <Typography color="error">Unknown Source!</Typography>
        )
      }
    />
  );
};

export const SourceChipSkeleton = () => <Skeleton variant="rounded" width={240} />;
