import { useTranslation } from "react-i18next";
import { useUpdateUserLocaleMutation } from "../user.mutations";
import { useEffect } from "react";
import { useUserAccountQuery } from "../user.queries";
import { AVAILABLE_LANGUAGE_KEY } from "@netcero/netcero-common";

export const useSyncAccountToKeycloak = () => {
  const { i18n } = useTranslation();
  const userAccountQuery = useUserAccountQuery();
  const { isReady, updateUserLocaleMutation } = useUpdateUserLocaleMutation();

  // Read data from userAccount query initially
  useEffect(() => {
    if (!userAccountQuery.data) {
      return;
    }
    const userAccount = userAccountQuery.data;
    const userLocale = userAccount.attributes?.locale?.[0];
    if (userLocale && i18n.language !== userLocale) {
      void i18n.changeLanguage(userLocale);
    }
    // This is fine since we only want to run this when the api data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountQuery.data]);

  // Send changes to Keycloak
  useEffect(() => {
    // Only update the user's locale if the user is logged in and the locale has already been initially set from the userAccount
    if (!userAccountQuery.data || !isReady || updateUserLocaleMutation.isPending) {
      return;
    }

    const userAccount = userAccountQuery.data;
    const userLocale = userAccount.attributes?.locale?.[0];
    if (userLocale !== i18n.language) {
      updateUserLocaleMutation.mutate({
        // This is fine since only available langauges can be set for i18n
        locale: i18n.language as AVAILABLE_LANGUAGE_KEY,
      });
    }
  }, [userAccountQuery.data, isReady, i18n.language, updateUserLocaleMutation]);
};
