import { FC, useCallback, useMemo, useRef, useState } from "react";
import { IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { Box, Collapse, Divider, IconButton, SvgIcon, Tooltip, Typography } from "@mui/material";
import { ExpansionIconButton } from "../../common/components/expansion-icon-button.component";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";
import { StyledCard } from "../../common/components/styled-card.component";
import { useDeoEsrsInputContext } from "./deo-esrs-input.context";
import { AuditLogIcon, InfoIcon } from "../../common/constants/tabler-icon.constants";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { useTranslation } from "react-i18next";
import { LinkChip } from "../../common/components/link-chip.component";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";
import { DrInputParametersList } from "./dr-input-parameters-list.component";
import { DrValueEditor } from "./value-editing/dr-value-editor.component";
import { ConditionalRecordingInfo } from "../components/conditional-recoding-info.component";
import { useScrollPosition } from "../../common/hooks/use-scroll-position.hook";
import { FillInHelpersComponent } from "./fill-in-helpers/fill-in-helpers.component";
import {
  CopyToClipboardButton,
  CopyToClipboardButtonAnimationProps,
} from "../../common/components/copy-to-clipboard-button.component";
import { SingleOrganizationUserAvatarPicker } from "../../user/components/single-organization-user-avatar-picker.component";
import { DataEntryObjectInputParameterStatusIndicatorWithMenu } from "../../data-entry-objects-input-parameters/components/data-entry-object-input-parameter-status-indicator-with-menu.component";
import {
  IDataEntryObjectInputParameterStatus,
  IIntercomEntity,
} from "@netcero/netcero-core-api-client";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { Link as RouterLink } from "react-router-dom";
import * as console from "node:console";
import { IntercomReferenceWidgetComponent } from "../../intercom-references/intercom-reference-widget.component";
import { PhaseInExplanationAlertForInputParameter } from "./phase-in-explanation-alert.component";

const COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME = "copy-dr-to-clipboard-button";

interface IDisclosureRequirementInputProps {
  disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement;
}

export const DisclosureRequirementInput: FC<IDisclosureRequirementInputProps> = ({
  disclosureRequirement,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_esrs_component");
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();

  const titleRef = useRef<HTMLDivElement>(null);
  const [showTitleDropShadow, setShowTitleDropShadow] = useState(false);
  useScrollPosition(16, () => {
    if (titleRef.current) {
      setShowTitleDropShadow(titleRef.current.getBoundingClientRect().y <= 0);
    }
  });

  const {
    expandedDisclosureRequirementIds,
    setExpandedDisclosureRequirementIds,
    organization,
    recordingPeriod,
    organizationStructure,
    recordingStructure,
    dataEntryObject,
    recordingStructureId,
    conditionalDisplayInputParametersLookup,
    handleDisclosureRequirementContributingUserIdsChange,
    handleDisclosureRequirementResponsibleUsersChange,
    handleCreateDrValue,
    handleUpdateDrValue,
    handleDeleteDrValue,
    handleUpdateTableValue,
    handleResetDisclosureRequirement,
    handleSubmitDisclosureRequirement,
    handleApproveDisclosureRequirement,
    handleRejectDisclosureRequirement,
    createDisclosureRequirementUrl,
    isLoading,
  } = useDeoEsrsInputContext();

  const isExpanded = useMemo(
    () => expandedDisclosureRequirementIds.includes(disclosureRequirement.id),
    [expandedDisclosureRequirementIds, disclosureRequirement.id],
  );
  const handleExpandDisclosureRequirement = useCallback(() => {
    setExpandedDisclosureRequirementIds((prev) =>
      prev.includes(disclosureRequirement.id)
        ? prev.filter((id) => id !== disclosureRequirement.id)
        : [...prev, disclosureRequirement.id],
    );
  }, [disclosureRequirement.id, setExpandedDisclosureRequirementIds]);

  const translatedDisclosureRequirementTitle = useMemo(
    () =>
      translateOptionalContent(
        disclosureRequirement.disclosureRequirementInputParameter?.inputParameter.name,
      ) ?? "Unknown Disclosure Requirement Input Parameter!",
    [
      disclosureRequirement.disclosureRequirementInputParameter?.inputParameter.name,
      translateOptionalContent,
    ],
  );

  const disclosureRequirementInputParameterConditionalRecordingInfo = useMemo(
    () => conditionalDisplayInputParametersLookup[disclosureRequirement.disclosureId] ?? null,
    [conditionalDisplayInputParametersLookup, disclosureRequirement.disclosureId],
  );
  const notConditionallyHiddenInputParameters = useMemo(
    () =>
      disclosureRequirement.inputParameters.filter((ip) => {
        const displayInformation = conditionalDisplayInputParametersLookup[ip.parameterId];
        return !displayInformation || displayInformation.displayMode !== "hide";
      }),
    [disclosureRequirement.inputParameters, conditionalDisplayInputParametersLookup],
  );

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const copyLinkComponent = useMemo(
    () => (
      <Box display="inline-block" className={COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}>
        <Box sx={{ transform: "translateY(-2px)" }}>
          <CopyToClipboardButton
            size="small"
            disableRipple
            value={createDisclosureRequirementUrl(disclosureRequirement)}
            tooltip={t("copy_link_tooltip", { ns: "data_entry_object_values_overview_common" })}
            tooltipSuccess={t("tooltip_copy_link_success", { ns: "buttons" })}
            tooltipPlacement="right"
          />
        </Box>
      </Box>
    ),
    [disclosureRequirement, createDisclosureRequirementUrl, t],
  );

  // Submission Handlers

  const [showConfirmSubmitDialog, setShowConfirmSubmitDialog] = useState(false);
  const handleSubmit = useCallback(() => {
    // Skip if no DR IP
    if (!disclosureRequirement.disclosureRequirementInputParameter) {
      console.error(
        "Cannot Submit. Unknown Disclosure Requirement Input Parameter!",
        disclosureRequirement,
      );
      return;
    }

    const areAllChildInputParametersComplete = disclosureRequirement.inputParameters
      .filter((inputParameter) => !!inputParameter.inputParameter)
      .every(
        (inputParameter) =>
          inputParameter.inputParameter!.status === IDataEntryObjectInputParameterStatus.Done,
      );

    if (areAllChildInputParametersComplete) {
      void handleSubmitDisclosureRequirement(
        disclosureRequirement.disclosureRequirementInputParameter.inputParameter,
      );
    } else {
      setShowConfirmSubmitDialog(true);
    }
  }, [disclosureRequirement, handleSubmitDisclosureRequirement]);
  const handleConfirmIncompleteSubmission = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        void handleSubmitDisclosureRequirement(
          disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
        );
      }
      setShowConfirmSubmitDialog(false);
    },
    [disclosureRequirement.disclosureRequirementInputParameter, handleSubmitDisclosureRequirement],
  );

  const infoTooltip = useMemo(
    () => translateContent(disclosureRequirement.info),
    [translateContent, disclosureRequirement.info],
  );

  return (
    <>
      {/* Confirm Submission Dialog */}
      <ConfirmDialogTextBody
        open={showConfirmSubmitDialog}
        onClose={handleConfirmIncompleteSubmission}
        title={t("confirm_submit_incomplete_dr_title")}
        content={t("confirm_submit_incomplete_dr_body")}
      />

      {/* DR Card */}
      <StyledCard
        id={disclosureRequirement.id}
        sx={{
          my: isExpanded ? 2 : 0,
          transition: "box-shadow 300ms, margin 300ms ease",
          ":hover": {
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 15px 5px",
          },
          position: "relative",
          overflow: "unset",
        }}
      >
        {/* DR Title Bar */}
        <Box
          ref={titleRef}
          display="flex"
          alignItems="center"
          gap={2}
          p={2}
          position="sticky"
          top={0}
          bgcolor="white"
          borderRadius={6}
          zIndex={99}
          boxShadow={
            isExpanded && showTitleDropShadow ? "rgba(0, 0, 0, 0.1) 0px 2px 15px 5px" : "none"
          }
          m={-0.5}
          sx={{
            transition: "box-shadow 100ms",
          }}
        >
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            gap={1}
            onClick={handleExpandDisclosureRequirement}
            sx={CopyToClipboardButtonAnimationProps(`.${COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}`)}
          >
            {/* Collapse Button */}
            <ExpansionIconButton
              expanded={isExpanded}
              iconButtonProps={{
                color: "inherit",
                sx: {
                  fontSize: "48px",
                },
              }}
            />
            {/* Title */}
            <LineClampTypographyWithTooltip
              tooltipOverride={translatedDisclosureRequirementTitle}
              onOverflowChange={setIsTextOverflowing}
              variant="h6"
              component="h3"
              maxLines={2}
              style={{ cursor: "pointer", textWrap: "pretty" }}
            >
              {translatedDisclosureRequirementTitle}
              {/* Copy IP Link - shown here when NOT overflowing (end of text) */}
              {!isTextOverflowing && copyLinkComponent}
            </LineClampTypographyWithTooltip>
            {/* Copy IP Link - shown here when overflowing (would be hidden by lineclamp) */}
            {isTextOverflowing && (
              <Box alignSelf="end" mt={-0.65} ml={-1}>
                {copyLinkComponent}
              </Box>
            )}
          </Box>
          {disclosureRequirement.disclosureRequirementInputParameter ? (
            <>
              {/* Status */}
              <DataEntryObjectInputParameterStatusIndicatorWithMenu
                type="disclosure-requirement"
                organizationId={organization.id}
                recordingPeriodId={recordingPeriod.id}
                dataEntryObjectId={dataEntryObject.id}
                recordingStructureId={recordingStructureId}
                dataEntryObjectInputParameter={
                  disclosureRequirement.disclosureRequirementInputParameter
                }
                onSubmit={handleSubmit}
                onReset={() =>
                  handleResetDisclosureRequirement(
                    disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                  )
                }
                onApprove={() =>
                  handleApproveDisclosureRequirement(
                    disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                  )
                }
                onReject={() =>
                  handleRejectDisclosureRequirement(
                    disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                  )
                }
                disabled={isLoading}
              />
              {/* Responsible Person */}
              <SingleOrganizationUserAvatarPicker
                organizationId={organization.id}
                value={
                  disclosureRequirement.disclosureRequirementInputParameter.responsibleUserId ??
                  null
                }
                tooltipNoUserSelected={t("add_responsible_user_tooltip", {
                  ns: "data_entry_object_values_overview_common",
                })}
                noUsersAvailableText={t("no_users_available_for_responsible_user", {
                  ns: "data_entry_object_values_overview_common",
                })}
                noUserOption
                noUserOptionText={t("no_responsible_user_value_text", {
                  ns: "data_entry_object_values_overview_common",
                })}
                onChange={(userId: string | null) =>
                  handleDisclosureRequirementResponsibleUsersChange(
                    disclosureRequirement.disclosureRequirementInputParameter!,
                    userId,
                  )
                }
                disabled={isLoading}
              />
            </>
          ) : (
            // Error message for unknown DR IP
            <Typography variant="body1" color="error">
              Unknown Disclosure Requirement Input Parameter! (Id:{" "}
              {disclosureRequirement.disclosureId})
            </Typography>
          )}
          {/* Description */}
          <IntercomReferenceWidgetComponent
            entityType={IIntercomEntity.DisclosureRequirement}
            identifier={disclosureRequirement.id}
            renderIfNoArticleFound={
              infoTooltip && (
                <Tooltip title={infoTooltip} placement="left">
                  <SvgIcon
                    component={InfoIcon}
                    color="action"
                    fontSize="medium"
                    sx={{ mr: 0.5, fill: "transparent" }}
                  />
                </Tooltip>
              )
            }
          />
        </Box>
        {/* Collapsable DR Content */}
        <Collapse in={isExpanded} unmountOnExit>
          <Box display="flex" flexDirection="column" gap={2} p={2}>
            {/* Second Row */}
            <Box display="flex" gap={1}>
              {/* EU Regulations */}
              {disclosureRequirement.metaData.linkToStandard && (
                <LinkChip
                  to={translateContent(disclosureRequirement.metaData.linkToStandard)}
                  label={t("eu_law_label")}
                />
              )}
              {disclosureRequirement.metaData.linkToAR && (
                <LinkChip
                  to={translateContent(disclosureRequirement.metaData.linkToAR)}
                  label={t("application_requirements_label")}
                />
              )}
              {/* Vertical Grow Spacer */}
              <Box flex={1} />
              {/* Contributors */}
              {disclosureRequirement.disclosureRequirementInputParameter ? (
                <OrganizationUsersComponent
                  values={
                    disclosureRequirement.disclosureRequirementInputParameter.contributingUserIds
                  }
                  organizationId={organization.id}
                  onChange={(newAssignedUserIds) =>
                    handleDisclosureRequirementContributingUserIdsChange(
                      disclosureRequirement.disclosureRequirementInputParameter!,
                      newAssignedUserIds,
                    )
                  }
                  tooltipAddButton={t("add_contributing_user_tooltip", {
                    ns: "data_entry_object_values_overview_common",
                  })}
                  emptyMessageAddButton={t("no_more_contributing_users_to_assign", {
                    ns: "data_entry_object_values_overview_common",
                  })}
                  emptyMessage={t("no_contributing_users", {
                    ns: "data_entry_object_values_overview_common",
                  })}
                  disabled={isLoading}
                />
              ) : (
                // Error message for unknown DR IP
                <Typography variant="body1" color="error">
                  Unknown Disclosure Requirement Input Parameter! (Id:{" "}
                  {disclosureRequirement.disclosureId})
                </Typography>
              )}
              {/* Audit Logs Button */}
              <Box display="flex" alignItems="center">
                <Tooltip title={t("title_audit_logging", { ns: "common" })}>
                  <IconButton
                    size="small"
                    component={RouterLink}
                    to={`/organizations/${organization.id}/configuration/audit-logging`}
                  >
                    <AuditLogIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {/* DR Phase In Info */}
            <PhaseInExplanationAlertForInputParameter
              inputParameter={
                disclosureRequirement.disclosureRequirementInputParameter?.inputParameter
              }
            />
            {/* Info about conditional recording */}
            <ConditionalRecordingInfo
              displayInformation={disclosureRequirementInputParameterConditionalRecordingInfo}
            />
            {/* DR Input */}
            {disclosureRequirement.disclosureRequirementInputParameter ? (
              <DrValueEditor
                deoInputParameter={disclosureRequirement.disclosureRequirementInputParameter}
                availableDistributionCriteria={[]}
                organizationId={organization.id}
                recordingPeriod={recordingPeriod}
                rootDataEntryObjectId={organizationStructure.id}
                dataEntryObjectId={dataEntryObject.id}
                disabled={isLoading}
                onCreate={(data) =>
                  handleCreateDrValue(
                    disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                    data,
                  )
                }
                onUpdate={(valueId, data) =>
                  handleUpdateDrValue(
                    disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                    valueId,
                    data,
                  )
                }
                onDelete={(valueId) =>
                  handleDeleteDrValue(
                    disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                    valueId,
                  )
                }
                onTableUpdate={(created, updated, deleted) =>
                  handleUpdateTableValue(
                    disclosureRequirement.disclosureRequirementInputParameter!.inputParameter,
                    created,
                    updated,
                    deleted,
                  )
                }
              />
            ) : (
              // Unknown DR IP (normally not shown since these IPs are filtered out)
              <Typography variant="body1" color="error">
                Unknown Disclosure Requirement Input Parameter! (Id:{" "}
                {disclosureRequirement.disclosureId})
              </Typography>
            )}
            {/* Fill In Helpers */}
            <FillInHelpersComponent
              disclosureRequirement={disclosureRequirement}
              organizationId={organization.id}
              recordingPeriodId={recordingPeriod.id}
              rootDataEntryObject={organizationStructure}
              dataEntryObjectId={dataEntryObject.id}
              esrsTopicIdentifier={recordingStructure.structure.topicIdentifier}
            />
            {/* Input Parameters Section (only shown when there are input parameters */}
            {notConditionallyHiddenInputParameters.length > 0 && (
              <>
                <Divider />
                {/* Input Parameters*/}
                <DrInputParametersList
                  disclosureRequirementInputParameters={notConditionallyHiddenInputParameters}
                  drIsExcluded={
                    !!disclosureRequirement.disclosureRequirementInputParameter?.exclude
                  }
                />
              </>
            )}
          </Box>
        </Collapse>
      </StyledCard>
    </>
  );
};
