import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { useMutation } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IDisclosureRequirementAssessmentData } from "@netcero/netcero-core-api-client";
import { getDrsAssessmentsQueryKey } from "./dr-assessment.queries";

interface IUpdateDrAssessmentMutationParams {
  organizationId: string;
  disclosureRequirementId: string;
  payload: IDisclosureRequirementAssessmentData;
}

export const useUpdateDrAssessmentMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: async ({
      organizationId,
      disclosureRequirementId,
      payload,
    }: IUpdateDrAssessmentMutationParams) =>
      CoreApiService.DisclosureRequirementAssessmentsApi.updateDisclosureRequirementAssessment(
        organizationId,
        disclosureRequirementId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (_, { organizationId }) =>
      invalidateQueries({
        awaitQueryKeys: [getDrsAssessmentsQueryKey(organizationId)],
      }),
  });
};
