/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IGetAllOrganizations200Response } from "../models";
/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns all organizations the user has access to/is part of.
     * @summary Get all organizations the user has access to/is part of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/organizations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns all organizations the user has access to/is part of.
     * @summary Get all organizations the user has access to/is part of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllOrganizations(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllOrganizations200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrganizations(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrganizationApiFp(configuration);
  return {
    /**
     * Returns all organizations the user has access to/is part of.
     * @summary Get all organizations the user has access to/is part of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOrganizations(options?: any): AxiosPromise<IGetAllOrganizations200Response> {
      return localVarFp.getAllOrganizations(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationApi - interface
 * @export
 * @interface OrganizationApi
 */
export interface OrganizationApiInterface {
  /**
   * Returns all organizations the user has access to/is part of.
   * @summary Get all organizations the user has access to/is part of.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiInterface
   */
  getAllOrganizations(options?: AxiosRequestConfig): AxiosPromise<IGetAllOrganizations200Response>;
}

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI implements OrganizationApiInterface {
  /**
   * Returns all organizations the user has access to/is part of.
   * @summary Get all organizations the user has access to/is part of.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public getAllOrganizations(options?: AxiosRequestConfig) {
    return OrganizationApiFp(this.configuration)
      .getAllOrganizations(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
