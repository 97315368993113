/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * esrs:TargetRelationshipToImpactsRisksAndOpportunitiesMember
 * @export
 * @enum {string}
 */

export const ITargetRelationshipToImpactsRisksAndOpportunitiesEnum = {
  ReducingNegativeImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember:
    "esrs:ReducingNegativeImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember",
  AdvancingPositiveImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember:
    "esrs:AdvancingPositiveImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember",
  ManagingMaterialRisksAndOpportunitiesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember:
    "esrs:ManagingMaterialRisksAndOpportunitiesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember",
} as const;

export type ITargetRelationshipToImpactsRisksAndOpportunitiesEnum =
  (typeof ITargetRelationshipToImpactsRisksAndOpportunitiesEnum)[keyof typeof ITargetRelationshipToImpactsRisksAndOpportunitiesEnum];
