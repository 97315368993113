/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IApplyAppliedDistributionCriteriaForRecordingPeriod404Response } from "../models";
// @ts-ignore
import { IDeleteAppliedDistributionCriteriaForRecordingPeriod409Response } from "../models";
// @ts-ignore
import { IDeleteDataEntryObjectDistributionCriteria404Response } from "../models";
// @ts-ignore
import { IUpdateDataEntryObjectDistributionCriteriaRequest } from "../models";
/**
 * DataEntryObjectDistributionCriteriaApi - axios parameter creator
 * @export
 */
export const DataEntryObjectDistributionCriteriaApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Delete a data entry object distribution criteria value
     * @summary Delete a data entry object distribution criteria value
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataEntryObjectDistributionCriteria: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      distributionCriteriaId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectDistributionCriteria",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectDistributionCriteria",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectDistributionCriteria",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'distributionCriteriaId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectDistributionCriteria",
        "distributionCriteriaId",
        distributionCriteriaId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/distribution-criteria/{distributionCriteriaId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(
            `{${"distributionCriteriaId"}}`,
            encodeURIComponent(String(distributionCriteriaId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a data entry object distribution criteria value
     * @summary Update a data entry object distribution criteria value
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {IUpdateDataEntryObjectDistributionCriteriaRequest} [iUpdateDataEntryObjectDistributionCriteriaRequest] Data Entry Object Distribution Criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObjectDistributionCriteria: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      distributionCriteriaId: string,
      iUpdateDataEntryObjectDistributionCriteriaRequest?: IUpdateDataEntryObjectDistributionCriteriaRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectDistributionCriteria",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectDistributionCriteria",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectDistributionCriteria",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'distributionCriteriaId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectDistributionCriteria",
        "distributionCriteriaId",
        distributionCriteriaId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/distribution-criteria/{distributionCriteriaId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(
            `{${"distributionCriteriaId"}}`,
            encodeURIComponent(String(distributionCriteriaId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUpdateDataEntryObjectDistributionCriteriaRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataEntryObjectDistributionCriteriaApi - functional programming interface
 * @export
 */
export const DataEntryObjectDistributionCriteriaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DataEntryObjectDistributionCriteriaApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete a data entry object distribution criteria value
     * @summary Delete a data entry object distribution criteria value
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDataEntryObjectDistributionCriteria(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      distributionCriteriaId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDataEntryObjectDistributionCriteria(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          distributionCriteriaId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update a data entry object distribution criteria value
     * @summary Update a data entry object distribution criteria value
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {IUpdateDataEntryObjectDistributionCriteriaRequest} [iUpdateDataEntryObjectDistributionCriteriaRequest] Data Entry Object Distribution Criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDataEntryObjectDistributionCriteria(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      distributionCriteriaId: string,
      iUpdateDataEntryObjectDistributionCriteriaRequest?: IUpdateDataEntryObjectDistributionCriteriaRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDataEntryObjectDistributionCriteria(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          distributionCriteriaId,
          iUpdateDataEntryObjectDistributionCriteriaRequest,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DataEntryObjectDistributionCriteriaApi - factory interface
 * @export
 */
export const DataEntryObjectDistributionCriteriaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataEntryObjectDistributionCriteriaApiFp(configuration);
  return {
    /**
     * Delete a data entry object distribution criteria value
     * @summary Delete a data entry object distribution criteria value
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataEntryObjectDistributionCriteria(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      distributionCriteriaId: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteDataEntryObjectDistributionCriteria(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          distributionCriteriaId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a data entry object distribution criteria value
     * @summary Update a data entry object distribution criteria value
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {IUpdateDataEntryObjectDistributionCriteriaRequest} [iUpdateDataEntryObjectDistributionCriteriaRequest] Data Entry Object Distribution Criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObjectDistributionCriteria(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      distributionCriteriaId: string,
      iUpdateDataEntryObjectDistributionCriteriaRequest?: IUpdateDataEntryObjectDistributionCriteriaRequest,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .updateDataEntryObjectDistributionCriteria(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          distributionCriteriaId,
          iUpdateDataEntryObjectDistributionCriteriaRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DataEntryObjectDistributionCriteriaApi - interface
 * @export
 * @interface DataEntryObjectDistributionCriteriaApi
 */
export interface DataEntryObjectDistributionCriteriaApiInterface {
  /**
   * Delete a data entry object distribution criteria value
   * @summary Delete a data entry object distribution criteria value
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectDistributionCriteriaApiInterface
   */
  deleteDataEntryObjectDistributionCriteria(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<any>;

  /**
   * Update a data entry object distribution criteria value
   * @summary Update a data entry object distribution criteria value
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {IUpdateDataEntryObjectDistributionCriteriaRequest} [iUpdateDataEntryObjectDistributionCriteriaRequest] Data Entry Object Distribution Criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectDistributionCriteriaApiInterface
   */
  updateDataEntryObjectDistributionCriteria(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    distributionCriteriaId: string,
    iUpdateDataEntryObjectDistributionCriteriaRequest?: IUpdateDataEntryObjectDistributionCriteriaRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<any>;
}

/**
 * DataEntryObjectDistributionCriteriaApi - object-oriented interface
 * @export
 * @class DataEntryObjectDistributionCriteriaApi
 * @extends {BaseAPI}
 */
export class DataEntryObjectDistributionCriteriaApi
  extends BaseAPI
  implements DataEntryObjectDistributionCriteriaApiInterface
{
  /**
   * Delete a data entry object distribution criteria value
   * @summary Delete a data entry object distribution criteria value
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectDistributionCriteriaApi
   */
  public deleteDataEntryObjectDistributionCriteria(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectDistributionCriteriaApiFp(this.configuration)
      .deleteDataEntryObjectDistributionCriteria(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        distributionCriteriaId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a data entry object distribution criteria value
   * @summary Update a data entry object distribution criteria value
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {IUpdateDataEntryObjectDistributionCriteriaRequest} [iUpdateDataEntryObjectDistributionCriteriaRequest] Data Entry Object Distribution Criteria
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectDistributionCriteriaApi
   */
  public updateDataEntryObjectDistributionCriteria(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    distributionCriteriaId: string,
    iUpdateDataEntryObjectDistributionCriteriaRequest?: IUpdateDataEntryObjectDistributionCriteriaRequest,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectDistributionCriteriaApiFp(this.configuration)
      .updateDataEntryObjectDistributionCriteria(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        distributionCriteriaId,
        iUpdateDataEntryObjectDistributionCriteriaRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
