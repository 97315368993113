import {
  IconActivity,
  IconAdjustmentsHorizontal,
  IconAlertTriangle,
  IconArrowBackUp,
  IconArrowBarDown,
  IconArrowBarUp,
  IconArrowDown,
  IconArrowRight,
  IconArrowUp,
  IconArticle,
  IconCalculator,
  IconCalendar,
  IconCalendarMinus,
  IconCalendarMonth,
  IconCalendarOff,
  IconCalendarPlus,
  IconCaretUpDown,
  IconCaretUpDownFilled,
  IconCategoryMinus,
  IconCategoryPlus,
  IconChartLine,
  IconCheck,
  IconChecks,
  IconCheckupList,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconCircleMinus,
  IconCirclePlus,
  IconCircleX,
  IconClipboard,
  IconClipboardCheck,
  IconColumns,
  IconCopy,
  IconCornerDownLeft,
  IconCornerDownRightDouble,
  IconCornerRightUpDouble,
  IconCurrencyEuro,
  IconDashboard,
  IconDatabaseExport,
  IconDiscount,
  IconDots,
  IconDownload,
  IconEdit,
  IconExclamationCircle,
  IconExternalLink,
  IconEye,
  IconEyeClosed,
  IconFile,
  IconFilePencil,
  IconFileText,
  IconFilter,
  IconHelp,
  IconHome,
  IconInfoCircle,
  IconInfoCircleFilled,
  IconKey,
  IconKeyOff,
  IconList,
  IconLoader,
  IconLogout,
  IconMathXy,
  IconMessage,
  IconMessageCheck,
  IconMessagePlus,
  IconMessageReport,
  IconMessages,
  IconMessageX,
  IconMinus,
  IconNote,
  IconNumber,
  IconPlus,
  IconPoint,
  IconPointFilled,
  IconReload,
  IconRuler2,
  IconSearch,
  IconSelect,
  IconSelector,
  IconSettingsAutomation,
  IconShield,
  IconSquareCheck,
  IconSquareMinus,
  IconSquarePlus,
  IconTable,
  IconTablePlus,
  IconTargetArrow,
  IconTextPlus,
  IconTilde,
  IconTrash,
  IconUpload,
  IconUser,
  IconUsers,
  IconWorld,
  IconX,
  TablerIconsProps,
} from "@tabler/icons-react";
import { FC, forwardRef } from "react";

export enum IconSize {
  Small = 16,
  Smaller = 20,
  Medium = 24,
  Larger = 28,
  Large = 32,
}

const DEFAULT_ICON_SIZE = IconSize.Smaller;

//Set default props for all icons
function setDefaultIconProps<T extends TablerIconsProps>(Icon: FC<T>) {
  return forwardRef((props: T, ref) => {
    return <Icon size={DEFAULT_ICON_SIZE} stroke={1.5} {...props} ref={ref} />;
  });
}

export const ChevronUpIcon = setDefaultIconProps(IconChevronUp);
export const ChevronDownIcon = setDefaultIconProps(IconChevronDown);
export const ChevronLeftIcon = setDefaultIconProps(IconChevronLeft);
export const ChevronRightIcon = setDefaultIconProps(IconChevronRight);

export const CornerDownLeftIcon = setDefaultIconProps(IconCornerDownLeft);
export const EnterIcon = CornerDownLeftIcon;
export const CornerDownRightUpDoubleIcon = setDefaultIconProps(IconCornerRightUpDouble);
export const CornerDownRightDoubleIcon = setDefaultIconProps(IconCornerDownRightDouble);

export const CheckIcon = setDefaultIconProps(IconCheck);

export const ChecksIcon = setDefaultIconProps(IconChecks);
export const CheckAllIcon = ChecksIcon;

export const ErrorIcon = setDefaultIconProps(IconExclamationCircle);

export const CircleXIcon = setDefaultIconProps(IconCircleX);
export const ErrorCircleIcon = CircleXIcon;

export const HelpIcon = setDefaultIconProps(IconHelp);

export const FilePencilIcon = setDefaultIconProps(IconFilePencil);
export const EditFilesIcon = FilePencilIcon;

export const FileIcon = setDefaultIconProps(IconFile);
export const EntryDataIcon = FileIcon;

export const AdjustmentsHorizontalIcon = setDefaultIconProps(IconAdjustmentsHorizontal);
export const ConfigureIcon = AdjustmentsHorizontalIcon;

export const CalendarIcon = setDefaultIconProps(IconCalendar);
export const CalendarMonthIcon = setDefaultIconProps(IconCalendarMonth);
export const RecordingPeriodIcon = CalendarIcon;
export const DateInputIcon = CalendarIcon;

export const CalendarOffIcon = setDefaultIconProps(IconCalendarOff);
export const CalendarRemoveIcon = CalendarOffIcon;

export const CalendarPlusIcon = setDefaultIconProps(IconCalendarPlus);
export const CalendarAddIcon = CalendarPlusIcon;

export const CalendarMinusIcon = setDefaultIconProps(IconCalendarMinus);

export const UserIcon = setDefaultIconProps(IconUser);
export const NoUserIcon = UserIcon;

export const UsersIcon = setDefaultIconProps(IconUsers);
export const StakeholderIcon = UsersIcon;

export const OpenInNewTabIcon = setDefaultIconProps(IconExternalLink);

export const ClipboardIcon = setDefaultIconProps(IconClipboard);

export const ClipboardCheckIcon = setDefaultIconProps(IconClipboardCheck);

export const EditIcon = setDefaultIconProps(IconEdit);

export const TrashIcon = setDefaultIconProps(IconTrash);
export const DeleteIcon = TrashIcon;

export const ArrowBackUpIcon = setDefaultIconProps(IconArrowBackUp);
export const UndoIcon = ArrowBackUpIcon;

export const ArrowDownIcon = setDefaultIconProps(IconArrowDown);

export const ArrowUpIcon = setDefaultIconProps(IconArrowUp);

export const ArrowRightIcon = setDefaultIconProps(IconArrowRight);

export const ArrowBarUpIcon = setDefaultIconProps(IconArrowBarUp);

export const ArrowBarDownIcon = setDefaultIconProps(IconArrowBarDown);

export const InfoCircleFilledIcon = setDefaultIconProps(IconInfoCircleFilled);
export const InfoCircleIcon = setDefaultIconProps(IconInfoCircle);
export const InfoIcon = InfoCircleIcon;

export const CaretUpDownFilledIcon = setDefaultIconProps(IconCaretUpDownFilled);
export const CaretUpDownIcon = setDefaultIconProps(IconCaretUpDown);

export const SelectorIcon = setDefaultIconProps(IconSelector);

export const ChartLineIcon = setDefaultIconProps(IconChartLine);
export const TargetPathsIcon = ChartLineIcon;

export const DiscountIcon = setDefaultIconProps(IconDiscount);
export const TargetPathsReferenceBudgetsIcon = DiscountIcon;

export const KeyIcon = setDefaultIconProps(IconKey);
export const DistributionCriteriaIcon = KeyIcon;

export const KeyOffIcon = setDefaultIconProps(IconKeyOff);

export const LogoutIcon = setDefaultIconProps(IconLogout);

export const MessageIcon = setDefaultIconProps(IconMessage);
export const CommentIcon = MessageIcon;

export const MessagesIcon = setDefaultIconProps(IconMessages);

export const MessagePlusIcon = setDefaultIconProps(IconMessagePlus);

export const MessageXIcon = setDefaultIconProps(IconMessageX);

export const MessageCheckIcon = setDefaultIconProps(IconMessageCheck);

export const MessageReportIcon = setDefaultIconProps(IconMessageReport);

export const FilterIcon = setDefaultIconProps(IconFilter);

export const MinusIcon = setDefaultIconProps(IconMinus);
export const SubtractIcon = MinusIcon;

export const CalculatorIcon = setDefaultIconProps(IconCalculator);
export const CalculatedIcon = CalculatorIcon;

export const RulerIcon = setDefaultIconProps(IconRuler2);
export const MeasuredIcon = RulerIcon;

export const TildeIcon = setDefaultIconProps(IconTilde);
export const EstimatedIcon = TildeIcon;

export const NoteIcon = setDefaultIconProps(IconNote);

export const XIcon = setDefaultIconProps(IconX);
export const CloseIcon = XIcon;
export const CancelIcon = XIcon;

export const ReloadIcon = setDefaultIconProps(IconReload);

export const CircleMinusIcon = setDefaultIconProps(IconCircleMinus);
export const RemoveIcon = CircleMinusIcon;

export const AlertTriangleIcon = setDefaultIconProps(IconAlertTriangle);
export const WarningIcon = AlertTriangleIcon;

export const SquarePlusIcon = setDefaultIconProps(IconSquarePlus);
export const SquareMinusIcon = setDefaultIconProps(IconSquareMinus);

export const CategoryPlusIcon = setDefaultIconProps(IconCategoryPlus);
export const CategoryMinusIcon = setDefaultIconProps(IconCategoryMinus);

export const SelectIcon = setDefaultIconProps(IconSelect);

export const DotsIcon = setDefaultIconProps(IconDots);

export const PlusIcon = setDefaultIconProps(IconPlus);

export const CirclePlusIcon = setDefaultIconProps(IconCirclePlus);
export const AddIcon = CirclePlusIcon;

export const TextPlusIcon = setDefaultIconProps(IconTextPlus);
export const ListPlusIcon = TextPlusIcon;

export const DashboardIcon = setDefaultIconProps(IconDashboard);

export const PointIcon = setDefaultIconProps(IconPoint);

export const PointFilledIcon = setDefaultIconProps(IconPointFilled);

export const LoaderIcon = setDefaultIconProps(IconLoader);

export const HourglassEmptyIcon = setDefaultIconProps(IconLoader);
export const TimerIcon = HourglassEmptyIcon;

export const HomeIcon = setDefaultIconProps(IconHome);

export const SearchIcon = setDefaultIconProps(IconSearch);

export const DatabaseExportIcon = setDefaultIconProps(IconDatabaseExport);

export const CopyIcon = setDefaultIconProps(IconCopy);

export const FileTextIcon = setDefaultIconProps(IconFileText);
export const SourcesIcon = FileTextIcon;

export const UploadIcon = setDefaultIconProps(IconUpload);

export const DownloadIcon = setDefaultIconProps(IconDownload);

export const WorldIcon = setDefaultIconProps(IconWorld);
export const LanguageIcon = WorldIcon;

export const ActivityIcon = setDefaultIconProps(IconActivity);
export const AuditLogIcon = ActivityIcon;

export const ClearIcon = setDefaultIconProps(IconX);

export const ArticleIcon = setDefaultIconProps(IconArticle);
export const TextInputIcon = ArticleIcon;

export const CurrencyEuroIcon = setDefaultIconProps(IconCurrencyEuro);
export const CurrencyInputIcon = CurrencyEuroIcon;

export const NumberIcon = setDefaultIconProps(IconNumber);
export const NumericInputIcon = NumberIcon;

export const SquareCheckIcon = setDefaultIconProps(IconSquareCheck);
export const BooleanInputIcon = SquareCheckIcon;

export const ListIcon = setDefaultIconProps(IconList);
export const OptionsInputIcon = ListIcon;

export const TableIcon = setDefaultIconProps(IconTable);
export const TableInputIcon = TableIcon;

export const TablePlusIcon = setDefaultIconProps(IconTablePlus);
export const AddRowIcon = TablePlusIcon;

export const ShieldIcon = setDefaultIconProps(IconShield);
export const PolicyInputIcon = ShieldIcon;

export const EyeClosedIcon = setDefaultIconProps(IconEyeClosed);
export const ShowIcon = EyeClosedIcon;

export const EyeIcon = setDefaultIconProps(IconEye);
export const HideIcon = EyeIcon;

export const MathXYIcon = setDefaultIconProps(IconMathXy);
export const ValueWithUnitInputIcon = MathXYIcon;

export const TargetArrowIcon = setDefaultIconProps(IconTargetArrow);
export const TargetInputIcon = TargetArrowIcon;

export const SettingsAutomationIcon = setDefaultIconProps(IconSettingsAutomation);
export const ActionIcon = SettingsAutomationIcon;

export const CheckupListIcon = setDefaultIconProps(IconCheckupList);
export const DisclosureRequirementsAssessmentIcon = CheckupListIcon;

export const ColumnsIcon = setDefaultIconProps(IconColumns);
export const ComplexInputIcon = ColumnsIcon;
