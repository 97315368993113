import {
  Badge,
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
} from "@netcero/netcero-core-api-client";
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TreeGroupContainer } from "../common/components/tree-group-container.component";
import {
  AddIcon,
  CommentIcon,
  EditIcon,
  IconSize,
  ListPlusIcon,
} from "../common/constants/tabler-icon.constants";
import { DMACategoryStatusIndicator } from "./common/dma-category-status-indicator.component";
import { IROStateIndicatorInline } from "./common/iro-state-inline-indicator.component";
import { MaterialityChip } from "./common/materiality-chip.component";
import { DMACategoryUtilities } from "./dialogs/dma-categories/dma-category.utilities";
import { useRenderDMACategoryName } from "./hooks/render-dma-category-name.hook";
import { DMACategoriesUtilities } from "@netcero/netcero-common";
import { IroAreasComponent } from "./common/iro-areas.component";
import { HOVER_BACKGROUND_COLOR } from "../../theme/theme";
import {
  useAllManualFeedbacksForDmaCategoryQuery,
  useAllStakeholderFeedbacksForCategoryQuery,
} from "./dma.queries";
import { useESRSTopicContext } from "./esrs-topic.context";

const MAX_DEPTH = 2;

interface IDMACategoryComponentProps {
  category: IDMACategoryWithEffectsAndChildren;
  showMaterialImpacts: boolean;
  showFinancialEffects: boolean;
  level?: number;
  showEmpty?: boolean;
  onCreateMaterialImpact: (category: IDMACategoryWithEffectsAndChildren) => void;
  onCreateFinancialEffect: (category: IDMACategoryWithEffectsAndChildren) => void;
  onEditMaterialImpact: (
    category: IDMACategoryWithEffectsAndChildren,
    materialImpact: IDMAMaterialImpact,
  ) => void;
  onEditFinancialEffect: (
    category: IDMACategoryWithEffectsAndChildren,
    financialEffect: IDMAFinancialEffect,
  ) => void;
  onEditDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onAddChildDMACategory: (parentCategory: IDMACategoryWithEffectsAndChildren) => void;
  onOptOutOfDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onEditOptOutOfDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onOptBackIntoDMACategory: (category: IDMACategoryWithEffectsAndChildren) => void;
  onClickFeedback: (category: IDMACategoryWithEffectsAndChildren) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

/**
 * Component for displaying and editing DMA Categories
 * @param category The category to display
 * @param showMaterial Impacts Whether to show material impacts
 * @param showFinancialEffects Whether to show financial effects
 * @param level The level of the category in the tree (1 based)
 * @param showEmpty Whether to show empty categories
 * Default: true
 * @param onCreateMaterialImpact Callback for creating a material impact
 * @param onCreateFinancialEffect Callback for creating a financial effect
 * @param onEditMaterialImpact Callback for editing a material impact
 * @param onEditFinancialEffect Callback for editing a financial effect
 * @param onEditDMACategory Callback for editing the category
 * @param onAddChildDMACategory Callback for adding a child category
 * @param onOptOutOfDMACategory Callback for opting out of the category
 * @param onEditOptOutOfDMACategory Callback for editing the opt-out of the category
 * @param onOptBackIntoDMACategory Callback for opting back into the category
 * @param onClickFeedback Callback for opening the feedback dialog
 */
export const DMACategoryComponent: FC<IDMACategoryComponentProps> = ({
  category,
  showMaterialImpacts,
  showFinancialEffects,
  level = 1,
  showEmpty = true,
  onEditFinancialEffect,
  onCreateFinancialEffect,
  onEditMaterialImpact,
  onCreateMaterialImpact,
  onEditDMACategory,
  onAddChildDMACategory,
  onOptOutOfDMACategory,
  onEditOptOutOfDMACategory,
  onOptBackIntoDMACategory,
  onClickFeedback,
  setOpen,
  open,
}) => {
  const { t } = useTranslation("double_materiality_assessment_sub_category_component");

  const hasIROs = useMemo(
    () => DMACategoryUtilities.doesCategoryOrChildrenContainIROs(category),
    [category],
  );

  const completeCategoryState = useMemo(() => {
    return DMACategoryUtilities.getCategoryTotalState(category);
  }, [category]);

  const showImpactsAndEffects = useMemo(() => !category.optOut, [category.optOut]);

  const showOptOutButton = useMemo(
    () =>
      !category.optOut && !hasIROs && completeCategoryState !== IDMACategoryState.Verified && !open,
    [category.optOut, hasIROs, completeCategoryState, open],
  );

  const renderName = useRenderDMACategoryName();

  useEffect(() => {
    setOpen(category.optOut);
  }, [category, setOpen]);

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1} pb={1}>
        <DMACategoryStatusIndicator state={completeCategoryState} />
        <Typography variant="h5" component="h2" pr={1}>
          {renderName(category)}
        </Typography>
        <DMACategoryActionsComponent
          dmaCategory={category}
          onEdit={() => onEditDMACategory(category)}
          canEdit={
            ![category.materialState, category.financialState].includes(IDMACategoryState.Verified)
          }
          onShowFeedback={() => onClickFeedback(category)}
          onAddChild={level < MAX_DEPTH ? () => onAddChildDMACategory(category) : undefined}
          onOptOut={
            showOptOutButton
              ? () => {
                  onOptOutOfDMACategory(category);
                }
              : undefined
          }
          showEdit={DMACategoriesUtilities.canCategoryBeAltered(category)}
        />
      </Box>

      <Box pl={1.25}>
        <TreeGroupContainer>
          <Box display="flex" flexDirection="column" gap={2}>
            {/* Opt Out Notice */}
            {!showImpactsAndEffects && (
              <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
                <Typography>{t("category_opted_out_notice")}</Typography>
                <Box display="flex" gap={2}>
                  <Typography fontWeight="bold" mt={0.15}>
                    {t("label_category_opted_out_reason")}
                  </Typography>
                  <Typography mt={0.15}>{category.optOutReason}</Typography>
                  <Tooltip title={t("button_edit_opt_out_reason_tooltip")}>
                    <IconButton size="small" onClick={() => onEditOptOutOfDMACategory(category)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex" gap={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      onOptBackIntoDMACategory(category);
                    }}
                  >
                    {t("button_opt_back_into_category")}
                  </Button>
                  {category.children.length !== 0 && (
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      sx={{ minWidth: 124 }}
                      onClick={() => setOpen(!open)}
                    >
                      {open ? t("show_actions") : t("hide_actions")}
                    </Button>
                  )}
                </Box>
              </Box>
            )}

            {/* Impacts & Effects */}
            {showImpactsAndEffects && (
              <Collapse in={!open}>
                {/* Material Impacts */}
                {showMaterialImpacts &&
                  (showEmpty || (!showEmpty && category.materialImpacts.length > 0)) && (
                    <Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h6">{t("material_impacts_header")}</Typography>
                        <MaterialityChip
                          materiality={category.materiality.materialityMaterial}
                          materialityDegree={category.materiality.materialityDegreeMaterial}
                        />
                      </Box>
                      {/* List Impacts */}
                      <Table size="small">
                        <TableBody>
                          {category.materialImpacts.map((materialImpact) => (
                            <TableRow
                              key={materialImpact.id}
                              onClick={() => onEditMaterialImpact(category, materialImpact)}
                              sx={{
                                cursor: "pointer",
                                ":hover": { bgcolor: HOVER_BACKGROUND_COLOR },
                              }}
                            >
                              <TableCell sx={{ width: "100%" /* grow table cell */ }}>
                                <Typography variant="body1" sx={{ display: "inline-block" }}>
                                  {materialImpact.title}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <MaterialityChip
                                  variant="outlined"
                                  materiality={materialImpact.material}
                                  materialityDegree={materialImpact.materialityDegree}
                                />
                              </TableCell>
                              <TableCell>
                                <IroAreasComponent typeOfIRO={materialImpact} />
                              </TableCell>
                              <TableCell align="left">
                                <IROStateIndicatorInline state={materialImpact.state} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {/* Empty Message */}
                      {category.materialImpacts.length === 0 && (
                        <Typography variant="body2" color="text.secondary" sx={{ px: 1, py: 1 }}>
                          {t("material_impacts_empty_message")}
                        </Typography>
                      )}
                      {/* Create Button */}
                      <Tooltip title={t("create_material_impact_tooltip")} placement="right">
                        <span>
                          <IconButton
                            color="primary"
                            onClick={() => onCreateMaterialImpact(category)}
                            disabled={category.materialState === IDMACategoryState.Verified}
                          >
                            <AddIcon size={IconSize.Medium} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  )}

                {/* Financial Effects */}
                {showFinancialEffects &&
                  (showEmpty || (!showEmpty && category.financialEffects.length > 0)) && (
                    <Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h6">{t("financial_effects_header")}</Typography>
                        <MaterialityChip
                          materiality={category.materiality.materialityFinancial}
                          materialityDegree={category.materiality.materialityDegreeFinancial}
                        />
                      </Box>
                      {/* Effects List */}
                      <Table size="small">
                        <TableBody>
                          {category.financialEffects.map((financialEffect) => (
                            <TableRow
                              key={financialEffect.id}
                              onClick={() => onEditFinancialEffect(category, financialEffect)}
                              sx={{
                                cursor: "pointer",
                                ":hover": { bgcolor: HOVER_BACKGROUND_COLOR },
                              }}
                            >
                              <TableCell sx={{ width: "100%" /* grow table cell */ }}>
                                <Typography variant="body1" sx={{ display: "inline-block" }}>
                                  {financialEffect.title}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <MaterialityChip
                                  variant="outlined"
                                  materiality={financialEffect.material}
                                  materialityDegree={financialEffect.materialityDegree}
                                />
                              </TableCell>
                              <TableCell>
                                <IroAreasComponent typeOfIRO={financialEffect} />
                              </TableCell>
                              <TableCell align="left">
                                <IROStateIndicatorInline state={financialEffect.state} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {/* Empty Message */}
                      {category.financialEffects.length === 0 && (
                        <Typography variant="body2" color="text.secondary" sx={{ px: 1, py: 1 }}>
                          {t("financial_effects_empty_message")}
                        </Typography>
                      )}
                      {/* Create Button */}
                      <Tooltip title={t("create_financial_effect_tooltip")} placement="right">
                        <span>
                          <IconButton
                            color="primary"
                            onClick={() => onCreateFinancialEffect(category)}
                            disabled={category.financialState === IDMACategoryState.Verified}
                          >
                            <AddIcon size={IconSize.Medium} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  )}
              </Collapse>
            )}

            {/* Display Children */}
            <Box pt={1} pl={2}>
              {category.children.map((childCategory) => (
                <Box key={childCategory.id} mt={1} mb={4}>
                  <DMACategoryComponent
                    category={childCategory}
                    showMaterialImpacts={showMaterialImpacts}
                    showFinancialEffects={showFinancialEffects}
                    level={level + 1}
                    showEmpty={showEmpty}
                    onCreateMaterialImpact={onCreateMaterialImpact}
                    onCreateFinancialEffect={onCreateFinancialEffect}
                    onEditMaterialImpact={onEditMaterialImpact}
                    onEditFinancialEffect={onEditFinancialEffect}
                    onEditDMACategory={onEditDMACategory}
                    onAddChildDMACategory={onAddChildDMACategory}
                    onOptOutOfDMACategory={onOptOutOfDMACategory}
                    onEditOptOutOfDMACategory={onEditOptOutOfDMACategory}
                    onOptBackIntoDMACategory={onOptBackIntoDMACategory}
                    onClickFeedback={onClickFeedback}
                    setOpen={setOpen}
                    open={open}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </TreeGroupContainer>
      </Box>
    </Box>
  );
};

interface IDMACategoryActionsComponentProps {
  dmaCategory: IDMACategoryWithEffectsAndChildren;
  onEdit: () => void;
  canEdit: boolean;
  showEdit: boolean;
  onShowFeedback?: () => void;
  onAddChild?: () => void;
  onOptOut?: () => void;
}

const DMACategoryActionsComponent: FC<IDMACategoryActionsComponentProps> = ({
  dmaCategory,
  onEdit,
  canEdit,
  onShowFeedback,
  onAddChild,
  onOptOut,
  showEdit,
}) => {
  const { t } = useTranslation("double_materiality_assessment_sub_category_component", {
    keyPrefix: "dma_category_actions_component",
  });

  const { organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId } =
    useESRSTopicContext();

  // Load stakeholder feedbacks to display the count in the badge
  const stakeholderFeedbacksQuery = useAllStakeholderFeedbacksForCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  // Count the feedbacks that are not pending as we only want to display the amount of those
  const feedbackCount = useMemo(() => {
    return stakeholderFeedbacksQuery.data?.stakeholderFeedbacks.filter(
      (feedback) => DMACategoryUtilities.getFeedbackState(feedback) !== "pending",
    ).length;
  }, [stakeholderFeedbacksQuery.data]);

  // Also load manual feedbacks, so they are cached when opening the feedback dialog
  useAllManualFeedbacksForDmaCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  return (
    <>
      {showEdit && (
        <Tooltip title={t("tooltip_edit")}>
          <span>
            <IconButton size="small" onClick={onEdit} disabled={!canEdit}>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onShowFeedback && (
        <Tooltip title={t("tooltip_stakeholder_feedback")}>
          <Badge color="primary" badgeContent={feedbackCount} overlap="circular">
            <IconButton size="small" onClick={onShowFeedback}>
              <CommentIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      )}
      {onAddChild && (
        <Tooltip title={t("tooltip_add_child")}>
          <span>
            <IconButton size="small" onClick={onAddChild} disabled={!canEdit}>
              <ListPlusIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onOptOut && (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={onOptOut}
          sx={{ minWidth: 124 }}
        >
          {t("button_category_irrelevant")}
        </Button>
      )}
    </>
  );
};
