import {
  Alert,
  AlertTitle,
  Box,
  Button,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndFeedbacksAndChildren,
  IStakeholderFeedback,
  IStakeholderFeedbackType,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TreeGroupContainer } from "../../common/components/tree-group-container.component";
import {
  MessagePlusIcon,
  MessagesIcon,
  PointFilledIcon,
  PointIcon,
} from "../../common/constants/tabler-icon.constants";
import { IROStateIndicatorInline } from "../../double-materiality-assessment/common/iro-state-inline-indicator.component";
import { MaterialityChip } from "../../double-materiality-assessment/common/materiality-chip.component";
import { StakeholderFeedbackDialogMaterialityInfoComponent } from "../dialogs/stakeholder-feedback.dialog";
import {
  FeedbackIndicatorStatusEnum,
  StakeholderFeedbackUtilities,
} from "../utilities/stakeholder-feedback.utitilities";
import { useRenderDMACategoryName } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";

interface IStakeholderFeedbackCategoryComponentProps {
  stakeholderId: string;
  category: IDMACategoryWithEffectsAndFeedbacksAndChildren;
  level?: number;
  onShowFeedbackDialog: (
    feedback: IStakeholderFeedback,
    category: IDMACategoryWithEffectsAndFeedbacksAndChildren,
  ) => void;
  disabled?: boolean;
  parentCategoryStatus?: FeedbackIndicatorStatusEnum;
}

const useExtractFeedback = (category: IDMACategoryWithEffectsAndFeedbacksAndChildren) => {
  const financialFeedback = useMemo(
    () => category.feedback.find((f) => f.feedbackType === "financial"),
    [category.feedback],
  );

  const materialFeedback = useMemo(
    () => category.feedback.find((f) => f.feedbackType === "material"),
    [category.feedback],
  );

  return {
    financialFeedback: financialFeedback ?? null,
    materialFeedback: materialFeedback ?? null,
    hasToGiveMaterialFeedback: materialFeedback !== undefined,
    hasToGiveFinancialFeedback: financialFeedback !== undefined,
    hasToGiveFeedback: materialFeedback !== undefined || financialFeedback !== undefined,
  };
};

const computeStatusFromFeedback = (feedback: IStakeholderFeedback): FeedbackIndicatorStatusEnum =>
  StakeholderFeedbackUtilities.hasFeedbackBeenSubmitted(feedback)
    ? FeedbackIndicatorStatusEnum.Submitted
    : FeedbackIndicatorStatusEnum.NotSubmitted;

export const StakeholderFeedbackCategoryComponent: FC<
  IStakeholderFeedbackCategoryComponentProps
> = ({
  category,
  stakeholderId,
  onShowFeedbackDialog,
  level = 1,
  disabled,
  parentCategoryStatus,
}) => {
  const { t } = useTranslation("stakeholder_feedback_category_component");

  const {
    financialFeedback,
    materialFeedback,
    hasToGiveMaterialFeedback,
    hasToGiveFinancialFeedback,
    hasToGiveFeedback,
  } = useExtractFeedback(category);

  const textColor = useCallback(
    (type: IStakeholderFeedbackType) => {
      const hasToGiveFeedback =
        type === "material" ? hasToGiveMaterialFeedback : hasToGiveFinancialFeedback;
      return hasToGiveFeedback ? undefined : "text.secondary";
    },
    [hasToGiveMaterialFeedback, hasToGiveFinancialFeedback],
  );

  const categoryCompoundStatus = useMemo(
    () =>
      StakeholderFeedbackUtilities.getCompoundCategoryStatus(
        category,
        hasToGiveFeedback,
        parentCategoryStatus,
      ),
    [category, hasToGiveFeedback, parentCategoryStatus],
  );

  const isCategorySharedOrComplete =
    (category.materialState === IDMACategoryState.Shared ||
      category.materialState === IDMACategoryState.Verified) &&
    (category.financialState === IDMACategoryState.Shared ||
      category.financialState === IDMACategoryState.Verified);

  const isCategoryOpen =
    category.materialState === IDMACategoryState.Open ||
    category.financialState === IDMACategoryState.Open;

  const renderName = useRenderDMACategoryName();

  return (
    <Box mt={2} mb={4}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={1}
        pt={level === 1 ? 1 : 2}
        pb={1}
        pl={level === 1 ? 0 : 1}
      >
        <FeedbackIndicator status={categoryCompoundStatus} />
        <Typography
          variant="h5"
          component="h2"
          pr={1}
          color={!hasToGiveFeedback ? "text.secondary" : undefined}
        >
          {renderName(category)}
        </Typography>
      </Box>

      <TreeGroupContainer>
        <Box display="flex" flexDirection="column" gap={2}>
          {/* Show opt out reason outside of the dialog as well */}
          {category.optOut && (
            <StakeholderFeedbackDialogMaterialityInfoComponent
              mode="opt-out-only"
              optOutReason={category.optOutReason ?? null}
            />
          )}

          {/* Show not verified state */}
          {!isCategorySharedOrComplete && <AlertCategoryNotYetShared />}

          {/* Show open state */}
          {isCategoryOpen && <AlertCategoryOpen />}

          {/* Impacts & Effects */}
          {/* Material Impacts */}
          {/* Always render IROs, regardless of whether */}
          {(category.materialImpacts.length > 0 || hasToGiveMaterialFeedback) && (
            <Box>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                {materialFeedback && (
                  <FeedbackIndicator status={computeStatusFromFeedback(materialFeedback)} />
                )}
                <Typography variant="h6" color={textColor("material")}>
                  {t("material_impacts_header")}
                </Typography>
                {category.materiality.materialityDegreeMaterial !== 0 && (
                  <MaterialityChip
                    materiality={category.materiality.materialityMaterial}
                    materialityDegree={category.materiality.materialityDegreeMaterial}
                  />
                )}
                {materialFeedback && (
                  <FeedbackButton
                    type="impacts"
                    onClick={() => onShowFeedbackDialog(materialFeedback, category)}
                    edit={StakeholderFeedbackUtilities.hasFeedbackBeenSubmitted(materialFeedback)}
                    disabled={disabled || category.materialState === IDMACategoryState.Verified}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={2}>
                {/* Info about Verified state */}
                {category.materialState === IDMACategoryState.Verified && (
                  <InfoCategoryVerified type="material" />
                )}
                {/* Info in case no material impacts have been created yet*/}
                {!isCategoryOpen && category.materialImpacts.length === 0 && (
                  <InfoNoIROs type="material" />
                )}
              </Box>
              {/* List Impacts */}
              <Table size="small" sx={{ mt: 1 }}>
                <TableBody>
                  {category.materialImpacts.map((materialImpact) => (
                    <TableRow
                      key={materialImpact.id}
                      // onClick={() => onEditMaterialImpact(category, materialImpact)}
                      // sx={{ cursor: "pointer", ":hover": { bgcolor: HOVER_BACKGROUND_COLOR } }}
                    >
                      <TableCell sx={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          sx={{ display: "inline-block" }}
                          color={textColor("material")}
                        >
                          {materialImpact.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <MaterialityChip
                          variant="outlined"
                          materiality={materialImpact.material}
                          materialityDegree={materialImpact.materialityDegree}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <IROStateIndicatorInline state={materialImpact.state} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {/* Financial Effects */}
          {(category.financialEffects.length > 0 || hasToGiveFinancialFeedback) && (
            <Box>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                {financialFeedback && (
                  <FeedbackIndicator status={computeStatusFromFeedback(financialFeedback)} />
                )}
                <Typography variant="h6" color={textColor("financial")}>
                  {t("financial_effects_header")}
                </Typography>
                {category.materiality.materialityDegreeFinancial !== 0 && (
                  <MaterialityChip
                    materiality={category.materiality.materialityFinancial}
                    materialityDegree={category.materiality.materialityDegreeFinancial}
                  />
                )}
                {financialFeedback && (
                  <FeedbackButton
                    type="effects"
                    onClick={() => onShowFeedbackDialog(financialFeedback, category)}
                    edit={StakeholderFeedbackUtilities.hasFeedbackBeenSubmitted(financialFeedback)}
                    disabled={disabled || category.financialState === IDMACategoryState.Verified}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={2}>
                {/* Info about Verified state */}
                {category.financialState === IDMACategoryState.Verified && (
                  <InfoCategoryVerified type="financial" />
                )}
                {/* Info in case no financial effects have been created yet*/}
                {!isCategoryOpen && category.financialEffects.length === 0 && (
                  <InfoNoIROs type="financial" />
                )}
              </Box>
              {/* Effects List */}
              <Table size="small" sx={{ mt: 1 }}>
                <TableBody>
                  {category.financialEffects.map((financialEffect) => (
                    <TableRow
                      key={financialEffect.id}
                      // onClick={() => onEditFinancialEffect(category, financialEffect)}
                      // sx={{ cursor: "pointer", ":hover": { bgcolor: HOVER_BACKGROUND_COLOR } }}
                    >
                      <TableCell sx={{ width: "100%" }}>
                        <Typography
                          variant="body1"
                          sx={{ display: "inline-block" }}
                          color={textColor("financial")}
                        >
                          {financialEffect.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <MaterialityChip
                          variant="outlined"
                          materiality={financialEffect.material}
                          materialityDegree={financialEffect.materialityDegree}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <IROStateIndicatorInline state={financialEffect.state} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {/* Display Children */}
          <Box>
            {category.children.map((childCategory) => (
              <StakeholderFeedbackCategoryComponent
                key={childCategory.id}
                category={childCategory}
                level={level + 1}
                stakeholderId={stakeholderId}
                onShowFeedbackDialog={onShowFeedbackDialog}
                disabled={disabled}
                // it is only relevant whether the parent has submitted feedback, not whether all of the children have submitted
                parentCategoryStatus={
                  StakeholderFeedbackUtilities.hasAllFeedbackBeenSubmitted(category.feedback)
                    ? FeedbackIndicatorStatusEnum.Submitted
                    : FeedbackIndicatorStatusEnum.NotSubmitted
                }
              />
            ))}
          </Box>
        </Box>
      </TreeGroupContainer>
    </Box>
  );
};

interface IFeedbackButtonProps {
  type: "effects" | "impacts";
  edit: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const FeedbackButton: FC<IFeedbackButtonProps> = ({ type, edit, onClick, disabled }) => {
  const { t } = useTranslation("stakeholder_feedback_category_component");
  return (
    <Tooltip
      title={t(edit ? "tooltip_edit_feedback" : "tooltip_add_feedback", { context: type })}
      placement="right"
    >
      <span>
        <Button
          variant="text"
          size="small"
          startIcon={edit ? <MessagesIcon /> : <MessagePlusIcon />}
          onClick={onClick}
          disabled={disabled}
          sx={{ ml: 2 }}
        >
          {t(edit ? "button_edit_feedback" : "button_add_feedback")}
        </Button>
      </span>
    </Tooltip>
  );
};

interface IFeedbackIndicatorProps {
  status: FeedbackIndicatorStatusEnum;
}

export const FeedbackIndicator: FC<IFeedbackIndicatorProps> = ({ status }) => {
  const { t } = useTranslation("stakeholder_feedback_category_component", {
    keyPrefix: "feedback_indicator",
  });

  const Icon = useMemo(() => {
    switch (status) {
      case FeedbackIndicatorStatusEnum.Submitted:
      case FeedbackIndicatorStatusEnum.ParentSubmitted:
        return PointFilledIcon;
      case FeedbackIndicatorStatusEnum.NotSubmitted:
      case FeedbackIndicatorStatusEnum.ParentNotSubmitted:
      default:
        return PointIcon;
    }
  }, [status]);

  const color = useMemo(() => {
    switch (status) {
      case FeedbackIndicatorStatusEnum.Submitted:
        return "success";
      case FeedbackIndicatorStatusEnum.NotSubmitted:
        return "warning";
      case FeedbackIndicatorStatusEnum.ParentNotSubmitted:
      case FeedbackIndicatorStatusEnum.ParentSubmitted:
      default:
        return "action";
    }
  }, [status]);

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={t(`status_${status}`)}>
        <SvgIcon color={color}>
          <Icon />
        </SvgIcon>
      </Tooltip>
    </Box>
  );
};

const AlertCategoryNotYetShared: FC = () => {
  const { t } = useTranslation("stakeholder_feedback_category_component");
  return (
    <Alert severity="warning">
      <AlertTitle>{t("alert_not_yet_shared.title")}</AlertTitle>
      {t("alert_not_yet_shared.content")}
    </Alert>
  );
};

const AlertCategoryOpen: FC = () => {
  const { t } = useTranslation("stakeholder_feedback_category_component");
  return (
    <Alert severity="warning">
      <AlertTitle>{t("alert_category_open.title")}</AlertTitle>
      {t("alert_category_open.content")}
    </Alert>
  );
};

interface IAlertNoIROCreatedYetProps {
  type: IStakeholderFeedbackType;
}

const InfoNoIROs: FC<IAlertNoIROCreatedYetProps> = ({ type }) => {
  const { t } = useTranslation("stakeholder_feedback_category_component");
  return <Alert severity="info">{t(`alert_no_iro_${type}`)}</Alert>;
};

interface IInfoCategoryVerifiedProps {
  type: IStakeholderFeedbackType;
}

const InfoCategoryVerified: FC<IInfoCategoryVerifiedProps> = ({ type }) => {
  const { t } = useTranslation("stakeholder_feedback_category_component");
  return <Alert severity="info">{t(`category_verified_notice_${type}`)}</Alert>;
};
