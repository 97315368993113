import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDrsAssessmentsQuery,
  useGetDrsAvailableForAssessmentQuery,
} from "../../modules/disclosure-requirement-assessment/dr-assessment.queries";
import { QueriesWrapper } from "../../modules/common/components/queries-wrapper.component";
import { DrEggOverview } from "../../modules/disclosure-requirement-assessment/components/dr-egg-overview.component";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";

interface IDrEggOverviewPageProps {}

export const DrEggOverviewPage: FC<IDrEggOverviewPageProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("dr_egg_overview");

  const { organizationId } = useParams();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: {
        organizationId: organizationId ?? "",
        onChange: (organizationId) => {
          navigate(`/organizations/${organizationId}/disclosure-requirements/assessment`);
        },
      },
      navigationItems: {
        activePath: `/organizations/${organizationId}/disclosure-requirements/assessment`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const drsForAssessmentsQuery = useGetDrsAvailableForAssessmentQuery();
  const drsAssessments = useGetDrsAssessmentsQuery(organizationId!);

  return (
    <Box width={1920} maxWidth="100%" mx="auto" p={4}>
      <Typography variant="h1" mb={2}>
        {t("title")}
      </Typography>
      <QueriesWrapper
        queries={[drsForAssessmentsQuery, drsAssessments]}
        build={([drsForAssessments, drsAssessments]) => (
          <DrEggOverview
            organizationId={organizationId!}
            drsForAssessment={drsForAssessments.disclosureRequirements}
            assessments={drsAssessments.assessments}
          />
        )}
        centerLoader
      />
    </Box>
  );
};
