import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getDrsAvailableForAssessmentQueryKey = () => ["disclosure-requirements", "assessment"];
export const useGetDrsAvailableForAssessmentQuery = () => {
  return useQuery({
    queryKey: getDrsAvailableForAssessmentQueryKey(),
    queryFn: async () =>
      CoreApiService.DisclosureRequirementAssessmentsApi.getAvailableDisclosureRequirementsForAssessment()
        .then((req) => req())
        .then((res) => res.data),
  });
};

export const getDrsAssessmentsQueryKey = (organizationId: string) => [
  ...getDrsAvailableForAssessmentQueryKey(),
  organizationId,
];
export const useGetDrsAssessmentsQuery = (organizationId: string) => {
  return useQuery({
    queryKey: getDrsAssessmentsQueryKey(organizationId),
    queryFn: async () =>
      CoreApiService.DisclosureRequirementAssessmentsApi.getDisclosureRequirementAssessments(
        organizationId,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
};
