import { FC, useCallback, useEffect, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { IDrAssessmentTableDrData, IDrAssessmentUsedValues } from "../dr-assessment.types";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { ExpansionIconButton } from "../../common/components/expansion-icon-button.component";
import { Controller, useForm } from "react-hook-form";
import {
  IDisclosureRequirementAssessmentData,
  IInputParameterESRSMetaDataDRInformation,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { HelpIcon } from "../../common/constants/tabler-icon.constants";
import { useIntercom } from "react-use-intercom";
import { DrEggTableAutocompleteMultiple } from "./dr-egg-table-autocomplete-multiple.component";
import { ApiDataCleanupUtilities } from "@netcero/netcero-common";
import { PHASE_IN_COLORS } from "../../../theme/theme";
import { DrEggExcludeInput } from "./dr-egg-exclude-input.component";
import { PhaseInExplanationAlert } from "../../data-entry-object-values/esrs/phase-in-explanation-alert.component";
import { isEnum } from "class-validator";
import { EligiblePhaseInDrsEfragIds } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";

function getDefaultAssessmentValues(
  values?: IDisclosureRequirementAssessmentData | null,
): IDisclosureRequirementAssessmentData {
  return {
    exclude: values?.exclude,
    responsiblePerson: values?.responsiblePerson ?? "",
    contributingPeople: values?.contributingPeople ?? [],
    organizationalUnits: values?.organizationalUnits ?? [],
    sources: values?.sources ?? [],
    notes: values?.notes ?? "",
  };
}

function getApiSubmissionData(
  values: IDisclosureRequirementAssessmentData,
): IDisclosureRequirementAssessmentData {
  return {
    exclude: values.exclude,
    responsiblePerson: ApiDataCleanupUtilities.cleanUpString(values.responsiblePerson),
    contributingPeople: ApiDataCleanupUtilities.cleanUpStringArray(values.contributingPeople),
    organizationalUnits: ApiDataCleanupUtilities.cleanUpStringArray(values.organizationalUnits),
    sources: ApiDataCleanupUtilities.cleanUpStringArray(values.sources),
    notes: ApiDataCleanupUtilities.cleanUpString(values.notes),
  };
}

interface IDrEggOverviewTableCommonProps {
  valuesInUse: IDrAssessmentUsedValues;
  expandedDrIds: string[];
  onChangeDrExpansion: (drId: string) => void;
  onSaveAssessment: (drId: string, values: IDisclosureRequirementAssessmentData) => void;
  disabled?: boolean;
}

interface IDrEggOverviewTableProps extends IDrEggOverviewTableCommonProps {
  rows: IDrAssessmentTableDrData[];
}

export const DrEggOverviewTable: FC<IDrEggOverviewTableProps> = ({ rows, ...props }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {rows.map((row) => (
            <DrEggOverviewTableRow
              key={row.disclosureRequirement.disclosureId}
              row={row}
              {...props}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface IDrEggOverviewTableRowProps extends IDrEggOverviewTableCommonProps {
  row: IDrAssessmentTableDrData;
}

const DrEggOverviewTableRow: FC<IDrEggOverviewTableRowProps> = ({
  row,
  valuesInUse,
  expandedDrIds,
  onChangeDrExpansion,
  onSaveAssessment,
  disabled,
}) => {
  const { t } = useTranslation("dr_egg_overview");
  const translateContent = useTranslateContent();

  const { showArticle } = useIntercom();

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty },
    getFieldState,
  } = useForm<IDisclosureRequirementAssessmentData>({
    defaultValues: getDefaultAssessmentValues(row.assessment),
  });
  useEffect(() => {
    reset(getDefaultAssessmentValues(row.assessment));
  }, [reset, row.assessment]);

  const isEligibleForPhaseIn = useMemo(
    () =>
      row.disclosureRequirement.eligibleForPhaseIn ===
      IInputParameterESRSMetaDataDRInformation.Eligible,
    [row.disclosureRequirement.eligibleForPhaseIn],
  );

  const drPhaseInExplanationKey = useMemo(
    () =>
      isEligibleForPhaseIn
        ? isEnum(row.disclosureRequirement.efragId, EligiblePhaseInDrsEfragIds)
          ? (row.disclosureRequirement.efragId as EligiblePhaseInDrsEfragIds)
          : null
        : null,
    [row.disclosureRequirement.efragId, isEligibleForPhaseIn],
  );

  const isExpanded = useMemo(
    () => expandedDrIds.includes(row.disclosureRequirement.disclosureId),
    [expandedDrIds, row.disclosureRequirement.disclosureId],
  );

  const handleSave = useCallback(
    (data: IDisclosureRequirementAssessmentData) => {
      onSaveAssessment(row.disclosureRequirement.disclosureId, getApiSubmissionData(data));
    },
    [row.disclosureRequirement.disclosureId, onSaveAssessment],
  );

  const handleBlurInput = useCallback(
    (field: keyof IDisclosureRequirementAssessmentData) => () => {
      const fieldState = getFieldState(field);
      if (fieldState.isDirty) {
        void handleSubmit(handleSave)();
      }
    },
    [getFieldState, handleSave, handleSubmit],
  );

  // Auto save on input changes
  useEffect(() => {
    if (isDirty) {
      void handleSubmit(handleSave)();
    }
    // eslint-disable-next-line
  }, [watch("exclude")]);

  return (
    <>
      <TableRow sx={{ "& td": { borderBottom: "none" } }}>
        <TableCell>
          <Box display="flex" alignItems="center">
            <ExpansionIconButton
              expanded={isExpanded}
              onClick={() => onChangeDrExpansion(row.disclosureRequirement.disclosureId)}
              iconButtonProps={{ size: "small" }}
            />
            <Typography
              variant="body2"
              fontWeight="bold"
              onClick={() => onChangeDrExpansion(row.disclosureRequirement.disclosureId)}
              mr={1}
              sx={{ cursor: "pointer" }}
            >
              {translateContent(row.disclosureRequirement.label)}
            </Typography>
            {row.disclosureRequirement.intercomId !== undefined && (
              <IconButton
                size="small"
                onClick={() => showArticle(row.disclosureRequirement.intercomId!)}
              >
                <SvgIcon component={HelpIcon} sx={{ fill: "transparent", height: 20, width: 20 }} />
              </IconButton>
            )}
            {/* Phase In Indicator */}
            {isEligibleForPhaseIn && (
              <Chip
                size="small"
                label={t("phase_in_indicator")}
                sx={{ ...PHASE_IN_COLORS, ml: 1 }}
              />
            )}
          </Box>
        </TableCell>
        {/* Exclude Checkbox */}
        <TableCell>
          <Controller
            control={control}
            name="exclude"
            render={({ field }) => (
              <DrEggExcludeInput
                isEligibleForPhaseIn={isEligibleForPhaseIn}
                value={field.value}
                onChange={field.onChange}
                disabled={disabled}
              />
            )}
          />
        </TableCell>
        {/* Responsible Person */}
        <TableCell>
          <Controller
            control={control}
            name="responsiblePerson"
            render={({ field }) => (
              <Autocomplete
                size="small"
                freeSolo
                autoSelect
                options={valuesInUse.people}
                value={field.value}
                onChange={(_, value) => field.onChange(value)}
                onBlur={handleBlurInput("responsiblePerson")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("labels.responsiblePerson")}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
                sx={{ minWidth: 230 }}
                disabled={disabled}
              />
            )}
          />
        </TableCell>
        {/* Data Entry Objects */}
        <TableCell sx={{ width: "33%" }}>
          <Controller
            control={control}
            name="organizationalUnits"
            render={({ field }) => (
              <DrEggTableAutocompleteMultiple
                label={t("labels.organizationalUnits")}
                autocompleteValues={valuesInUse.organizationalUnits}
                value={field.value}
                onChange={field.onChange}
                onSave={handleBlurInput("organizationalUnits")}
                disabled={disabled}
              />
            )}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" gap={2} pb={4}>
              <PhaseInExplanationAlert drPhaseInExplanationKey={drPhaseInExplanationKey} />
              {/* Contributing People */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="contributingPeople"
                    render={({ field }) => (
                      <DrEggTableAutocompleteMultiple
                        label={t("labels.contributingPeople")}
                        autocompleteValues={valuesInUse.people}
                        value={field.value}
                        onChange={field.onChange}
                        onSave={handleBlurInput("contributingPeople")}
                        disabled={disabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="sources"
                    render={({ field }) => (
                      <DrEggTableAutocompleteMultiple
                        label={t("labels.sources")}
                        autocompleteValues={valuesInUse.sources}
                        value={field.value}
                        onChange={field.onChange}
                        onSave={handleBlurInput("sources")}
                        disabled={disabled}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {/* Notes */}
              <Box flex={2}>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onBlur={handleBlurInput("notes")}
                      size="small"
                      label={t("labels.notes")}
                      multiline
                      minRows={3}
                      maxRows={5}
                      fullWidth
                      disabled={disabled}
                    />
                  )}
                />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
