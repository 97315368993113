/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IAllESRSTopicsResponse } from "../models";
// @ts-ignore
import { IGetDataEntryObject404Response } from "../models";
// @ts-ignore
import { IInitializeDma404Response } from "../models";
// @ts-ignore
import { IInitializeDmaForTopic404Response } from "../models";
/**
 * ESRSTopicsApi - axios parameter creator
 * @export
 */
export const ESRSTopicsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns all ESRS topics for the given data entry object, populated with the categories + effects.
     * @summary Get all ESRS topics for the given data entry object.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the ESRS topics.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEsrsTopics: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getAllEsrsTopics", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getAllEsrsTopics", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("getAllEsrsTopics", "dataEntryObjectId", dataEntryObjectId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Initializes the DMA for the given DEO
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeDma: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("initializeDma", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("initializeDma", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("initializeDma", "dataEntryObjectId", dataEntryObjectId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/init`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Initializes the DMA for the given DEO and the given topic
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeDmaForTopic: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("initializeDmaForTopic", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("initializeDmaForTopic", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("initializeDmaForTopic", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("initializeDmaForTopic", "esrsTopicId", esrsTopicId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/init`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ESRSTopicsApi - functional programming interface
 * @export
 */
export const ESRSTopicsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ESRSTopicsApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns all ESRS topics for the given data entry object, populated with the categories + effects.
     * @summary Get all ESRS topics for the given data entry object.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the ESRS topics.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEsrsTopics(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAllESRSTopicsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEsrsTopics(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Initializes the DMA for the given DEO
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initializeDma(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initializeDma(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Initializes the DMA for the given DEO and the given topic
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initializeDmaForTopic(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initializeDmaForTopic(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ESRSTopicsApi - factory interface
 * @export
 */
export const ESRSTopicsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ESRSTopicsApiFp(configuration);
  return {
    /**
     * Returns all ESRS topics for the given data entry object, populated with the categories + effects.
     * @summary Get all ESRS topics for the given data entry object.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the ESRS topics.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEsrsTopics(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: any,
    ): AxiosPromise<IAllESRSTopicsResponse> {
      return localVarFp
        .getAllEsrsTopics(organizationId, recordingPeriodId, dataEntryObjectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Initializes the DMA for the given DEO
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeDma(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .initializeDma(organizationId, recordingPeriodId, dataEntryObjectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Initializes the DMA for the given DEO and the given topic
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeDmaForTopic(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .initializeDmaForTopic(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ESRSTopicsApi - interface
 * @export
 * @interface ESRSTopicsApi
 */
export interface ESRSTopicsApiInterface {
  /**
   * Returns all ESRS topics for the given data entry object, populated with the categories + effects.
   * @summary Get all ESRS topics for the given data entry object.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the ESRS topics.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ESRSTopicsApiInterface
   */
  getAllEsrsTopics(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IAllESRSTopicsResponse>;

  /**
   *
   * @summary Initializes the DMA for the given DEO
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ESRSTopicsApiInterface
   */
  initializeDma(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Initializes the DMA for the given DEO and the given topic
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ESRSTopicsApiInterface
   */
  initializeDmaForTopic(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * ESRSTopicsApi - object-oriented interface
 * @export
 * @class ESRSTopicsApi
 * @extends {BaseAPI}
 */
export class ESRSTopicsApi extends BaseAPI implements ESRSTopicsApiInterface {
  /**
   * Returns all ESRS topics for the given data entry object, populated with the categories + effects.
   * @summary Get all ESRS topics for the given data entry object.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the ESRS topics.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ESRSTopicsApi
   */
  public getAllEsrsTopics(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ) {
    return ESRSTopicsApiFp(this.configuration)
      .getAllEsrsTopics(organizationId, recordingPeriodId, dataEntryObjectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Initializes the DMA for the given DEO
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ESRSTopicsApi
   */
  public initializeDma(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ) {
    return ESRSTopicsApiFp(this.configuration)
      .initializeDma(organizationId, recordingPeriodId, dataEntryObjectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Initializes the DMA for the given DEO and the given topic
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ESRSTopicsApi
   */
  public initializeDmaForTopic(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    options?: AxiosRequestConfig,
  ) {
    return ESRSTopicsApiFp(this.configuration)
      .initializeDmaForTopic(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
