import { Done, DoneAll, PriorityHigh } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  LinearProgress,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IBaseDMAFinancialEffectData,
  IBaseDMAMaterialImpactData,
  IDataEntryObject,
  IDMACategory,
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IDMAConfiguration,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
  IESRSTopic,
  IIROState,
  IOptOutOfDmaCategoryRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "../common/dialogs/variants/error.dialog";
import { PlusIcon } from "../common/constants/tabler-icon.constants";
import { DeleteImpactOrEffectConfirmDialog } from "./common/delete-impact-or-effect-confirm.dialog";
import { DMACategoryStatusIndicator } from "./common/dma-category-status-indicator.component";
import { EsrsTopicOptOutDialog } from "./common/esrs-topic-opt-out.dialog";
import { ExecuteDMAConfirmDialog } from "./common/execute-dma-confirm.dialog";
import {
  DMACategoryDialogs,
  ICategoryCompleteActionMetadata,
} from "./dialogs/dma-categories/dma-category-editing-dialogs.component";
import { useDMACategoryEditingDialogsState } from "./dialogs/dma-categories/dma-category-editing-dialogs.hook";
import { DMACategoryFeedbackDialog } from "./dialogs/dma-categories/dma-category-feedback.dialog";
import { DMACategoryOptBackInConfirmDialog } from "./dialogs/dma-categories/dma-category-opt-back-in-confirm.dialog";
import { DMACategoryOptOutDialog } from "./dialogs/dma-categories/dma-category-opt-out.dialog";
import { DMACategoryUtilities } from "./dialogs/dma-categories/dma-category.utilities";
import { DMAConfiguration } from "./dma-configuration.component";
import { ESRSTopicComponent } from "./esrs-topic.component";
import {
  ESRSTopicsStepperFloatingActionBarComponent,
  ESRSTopicsStepperFloatingActionBarComponentSpacer,
} from "./esrs-topics-stepper-floating-action-bar.component";
import { DMAFinancialEffectEditDialog } from "./financial-effects/dma-financial-effect-edit.dialog";
import { DMAMaterialImpactEditDialog } from "./material-impacts/dma-material-impact-edit.dialog";
import { useOptOutDMACategoryMutation } from "./mutations/dma-category.mutations";
import {
  useCreateFinancialEffectMutation,
  useCreateMaterialImpactMutation,
  useDeleteFinancialEffectMutation,
  useDeleteMaterialImpactMutation,
  useExecuteDMAMutation,
  useInitDMAForTopicMutation,
  useOptOutOfESRSTopicMutation,
  useUpdateFinancialEffectIROStateMutation,
  useUpdateFinancialEffectMutation,
  useUpdateMaterialImpactIROStateMutation,
  useUpdateMaterialImpactMutation,
} from "./mutations/dma.mutations";
import { IRecordingPeriodIdentity } from "../recording-periods/recording-period-identity.interface";
import { DMAUtilities } from "./utilities/dma.utilities";
import { useSyncCategoryToLS } from "./hooks/sync-category-to-ls.hook";
import { useRenderDMACategoryName } from "./hooks/render-dma-category-name.hook";
import { useRenderESRSTopicName } from "./hooks/render-esrs-topic-name.hook";

const ESRS_TOPIC_QUERY_PARAM = "esrsTopicId";
const DMA_CATEGORY_QUERY_PARAM = "dmaCategoryId";
const FILTER_BY_USER_QUERY_PARAM = "filterByUserId";

export interface IShowGroupsState {
  showMaterialImpacts: boolean;
  showFinancialEffects: boolean;
}

interface IShowEffectOrImpactCreateDialogState {
  open: boolean;
  category: IDMACategoryWithEffectsAndChildren | null;
  esrsTopic: IESRSTopic | null;
}

interface IShowEditOrDeleteDialogState<T> {
  open: boolean;
  category: IDMACategoryWithEffectsAndChildren | null;
  esrsTopic: IESRSTopic | null;
  effectOrImpact: T | null;
}

interface ITopicOptOutDialogState {
  open: boolean;
  esrsTopic: IESRSTopic | null;
}

interface ICategoryOptInOptOutDialogState {
  open: boolean;
  esrsTopic: IESRSTopic | null;
  category: IDMACategoryWithEffectsAndChildren | null;
}

interface IStakeholderFeedbackDialogState {
  open: boolean;
  esrsTopic: IESRSTopic | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
}

interface IDMACategoriesStepperComponentProps extends IRecordingPeriodIdentity {
  dmaConfiguration: IDMAConfiguration;
  esrsTopics: IESRSTopic[];
  dataEntryObject: IDataEntryObject;
}

export const ESRSTopicsStepperComponent: FC<IDMACategoriesStepperComponentProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObject,
  dmaConfiguration,
  esrsTopics,
}) => {
  // TODO: Possibly refactor financial effect and material impact modals into a single component (like with the dma category dialogs)

  const { t } = useTranslation("double_materiality_assessment_stepper_component");
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  // General Preparation

  const dataEntryObjectId = useMemo(() => dataEntryObject.id, [dataEntryObject.id]);

  // ESRS Topic and Category Logic
  const { getStoredCategoryId, saveCategoryId, clearSettingsForDataEntryObject } =
    useSyncCategoryToLS();

  const viewedESRSTopicId = useMemo(
    () => searchParams.get(ESRS_TOPIC_QUERY_PARAM) ?? null,
    [searchParams],
  );

  const viewedDMACategoryId = useMemo(
    () => searchParams.get(DMA_CATEGORY_QUERY_PARAM) ?? null,
    [searchParams],
  );

  const setViewSettings = useCallback(
    (esrsTopicId: string | null, dmaCategoryId: string | null) => {
      // Skip if no changes
      if (esrsTopicId === viewedESRSTopicId && dmaCategoryId === viewedDMACategoryId) {
        return;
      }

      // Update Query Params (and ui state)
      setSearchParams((params) => {
        if (esrsTopicId) {
          params.set(ESRS_TOPIC_QUERY_PARAM, esrsTopicId);
        } else {
          params.delete(ESRS_TOPIC_QUERY_PARAM);
        }

        if (dmaCategoryId) {
          params.set(DMA_CATEGORY_QUERY_PARAM, dmaCategoryId);
        } else {
          params.delete(DMA_CATEGORY_QUERY_PARAM);
        }

        return params;
      });

      if (esrsTopicId !== null) {
        saveCategoryId(dataEntryObjectId, { esrsTopicId, dmaCategoryId });
      }
      // Scroll to tops
      // window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    [dataEntryObjectId, saveCategoryId, setSearchParams, viewedDMACategoryId, viewedESRSTopicId],
  );

  // be sure to return to previously selected category
  useEffect(() => {
    // Only execute initially
    if (viewedESRSTopicId !== null) {
      return;
    }
    // Use Value from Local Storage
    const current = getStoredCategoryId(dataEntryObjectId);
    if (current !== null) {
      setViewSettings(current.esrsTopicId, current.dmaCategoryId);
    }
  }, [viewedESRSTopicId, dataEntryObjectId, getStoredCategoryId, setViewSettings]);

  // Available UserIds for filters

  const filterableUserIds = useMemo(() => {
    return DMAUtilities.getAllAssignedAndResponsibleUsersIdsFromTopics(esrsTopics);
  }, [esrsTopics]);

  const filterByUserIds = useMemo(
    () => searchParams.getAll(FILTER_BY_USER_QUERY_PARAM),
    [searchParams],
  );
  const setFilterByUserIds = useCallback(
    (userIds: string[]) => {
      if (
        userIds.length !== filterByUserIds.length ||
        userIds.every((id) => filterByUserIds.includes(id))
      ) {
        setSearchParams((params) => {
          // Clear current Value
          params.delete(FILTER_BY_USER_QUERY_PARAM);
          // Add all currently set values
          userIds.forEach((id) => params.append(FILTER_BY_USER_QUERY_PARAM, id));
          // Return
          return params;
        });
      }
    },
    [filterByUserIds, setSearchParams],
  );

  useEffect(() => {
    if (!filterByUserIds.every((id) => filterableUserIds.includes(id))) {
      setFilterByUserIds(filterByUserIds.filter((id) => filterableUserIds.includes(id)));
    }
  }, [filterByUserIds, filterableUserIds, setFilterByUserIds]);

  const shownTopics = useMemo(() => {
    return filterByUserIds.length > 0
      ? DMAUtilities.filterTopicsByUserIds(esrsTopics, filterByUserIds)
      : esrsTopics;
  }, [filterByUserIds, esrsTopics]);

  // Selected ESRS Topics State

  const [showGroups, setShowGroups] = useState<IShowGroupsState>({
    showMaterialImpacts: true,
    showFinancialEffects: true,
  });

  const selectedESRSTopic = useMemo(
    () => (viewedESRSTopicId ? shownTopics.find((t) => t.id === viewedESRSTopicId) ?? null : null),
    [shownTopics, viewedESRSTopicId],
  );

  const selectedDMACategory = useMemo(() => {
    if (selectedESRSTopic && viewedDMACategoryId) {
      return (
        selectedESRSTopic.recordedESRSTopic?.dmaCategories.find(
          (category) => category.id === viewedDMACategoryId,
        ) ?? null
      );
    }

    return null;
  }, [viewedDMACategoryId, selectedESRSTopic]);

  // State Cleanup
  useEffect(() => {
    // Reset filters if unknown topic
    if (selectedESRSTopic === null && viewedESRSTopicId !== null) {
      setViewSettings(shownTopics[0]?.id ?? null, null);
      return;
    }

    // Reset category if unknown category
    if (selectedDMACategory === null && viewedDMACategoryId !== null) {
      setViewSettings(selectedESRSTopic?.id ?? null, null);
    }

    // Set first child if no category is selected, but a topic is and has child categories
    if (
      selectedESRSTopic &&
      !selectedDMACategory &&
      selectedESRSTopic.recordedESRSTopic &&
      selectedESRSTopic.recordedESRSTopic.dmaCategories.length > 0
    ) {
      setViewSettings(
        selectedESRSTopic.id,
        selectedESRSTopic.recordedESRSTopic.dmaCategories[0].id,
      );
    }
  }, [
    selectedESRSTopic,
    viewedESRSTopicId,
    shownTopics,
    setViewSettings,
    selectedDMACategory,
    viewedDMACategoryId,
  ]);

  const selectedStepperIndex = useMemo(() => {
    return shownTopics.findIndex((t) => t.id === selectedESRSTopic?.id) + 1;
  }, [shownTopics, selectedESRSTopic]);

  const handleChangeStepperIndex = useCallback(
    (newIndex: number) => {
      const newTopic = shownTopics[newIndex - 1];
      setViewSettings(
        newTopic?.id ?? null,
        newTopic?.recordedESRSTopic?.dmaCategories[0]?.id ?? null,
      );
    },
    [shownTopics, setViewSettings],
  );

  // Material Impacts

  const [showCreateMaterialImpactDialog, setShowCreateMaterialImpactDialog] =
    useState<IShowEffectOrImpactCreateDialogState>({
      open: false,
      category: null,
      esrsTopic: null,
    });
  const [showEditMaterialImpactDialog, setShowEditMaterialImpactDialog] = useState<
    IShowEditOrDeleteDialogState<IDMAMaterialImpact>
  >({
    open: false,
    category: null,
    esrsTopic: null,
    effectOrImpact: null,
  });
  const [showDeleteMaterialImpactDialog, setShowDeleteMaterialImpactDialog] = useState<
    IShowEditOrDeleteDialogState<IDMAMaterialImpact>
  >({
    open: false,
    category: null,
    esrsTopic: null,
    effectOrImpact: null,
  });

  const createMaterialImpactMutation = useCreateMaterialImpactMutation();
  const updateMaterialImpactMutation = useUpdateMaterialImpactMutation();
  const deleteMaterialImpactMutation = useDeleteMaterialImpactMutation();
  const updateMaterialImpactIROStateMutation = useUpdateMaterialImpactIROStateMutation();

  const handleCreateMaterialImpact = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => {
    createMaterialImpactMutation.reset();
    setShowCreateMaterialImpactDialog({
      open: true,
      category,
      esrsTopic,
    });
  };
  const handleCloseCreateMaterialImpactDialog = async (data: IBaseDMAMaterialImpactData | null) => {
    // Handle create data
    if (data) {
      await createMaterialImpactMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showCreateMaterialImpactDialog.esrsTopic!.id,
        dmaCategoryId: showCreateMaterialImpactDialog.category!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowCreateMaterialImpactDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleUpdateMaterialImpact = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
    impact: IDMAMaterialImpact,
  ) => {
    updateMaterialImpactMutation.reset();
    updateMaterialImpactIROStateMutation.reset();
    setShowEditMaterialImpactDialog({
      open: true,
      category,
      esrsTopic,
      effectOrImpact: impact,
    });
  };
  const handleCloseUpdateMaterialImpactDialog = async (data: IBaseDMAMaterialImpactData | null) => {
    if (data) {
      await updateMaterialImpactMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showEditMaterialImpactDialog.esrsTopic!.id,
        dmaCategoryId: showEditMaterialImpactDialog.category!.id,
        materialImpactId: showEditMaterialImpactDialog.effectOrImpact!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowEditMaterialImpactDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleDeleteMaterialImpact = () => {
    deleteMaterialImpactMutation.reset();
    setShowDeleteMaterialImpactDialog({
      open: true,
      category: showEditMaterialImpactDialog.category,
      esrsTopic: showEditMaterialImpactDialog.esrsTopic,
      effectOrImpact: showEditMaterialImpactDialog.effectOrImpact,
    });
  };
  const handleCloseDeleteMaterialImpactDialog = async (confirm: boolean) => {
    if (confirm) {
      await deleteMaterialImpactMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showDeleteMaterialImpactDialog.esrsTopic!.id,
        dmaCategoryId: showDeleteMaterialImpactDialog.category!.id,
        materialImpactId: showDeleteMaterialImpactDialog.effectOrImpact!.id,
      });

      // Close Edit Dialog after delete
      setShowEditMaterialImpactDialog((state) => ({
        ...state,
        open: false,
      }));
    }
    // Close Dialogs
    setShowDeleteMaterialImpactDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleUpdateMaterialImpactIROState = async (newState: IIROState) => {
    const updatedImpact = await updateMaterialImpactIROStateMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId: selectedESRSTopic?.id ?? "",
      dmaCategoryId: showEditMaterialImpactDialog.category!.id,
      materialImpactId: showEditMaterialImpactDialog.effectOrImpact!.id,
      iroState: newState,
    });

    setShowEditMaterialImpactDialog((state) => ({ ...state, effectOrImpact: updatedImpact }));
  };

  // Financial Effects

  const [showCreateFinancialEffectDialog, setShowCreateFinancialEffectDialog] =
    useState<IShowEffectOrImpactCreateDialogState>({
      open: false,
      category: null,
      esrsTopic: null,
    });
  const [showEditFinancialEffectDialog, setShowEditFinancialEffectDialog] = useState<
    IShowEditOrDeleteDialogState<IDMAFinancialEffect>
  >({
    open: false,
    category: null,
    esrsTopic: null,
    effectOrImpact: null,
  });
  const [showDeleteFinancialEffectDialog, setShowDeleteFinancialEffectDialog] = useState<
    IShowEditOrDeleteDialogState<IDMAFinancialEffect>
  >({
    open: false,
    category: null,
    esrsTopic: null,
    effectOrImpact: null,
  });

  const createFinancialEffectMutation = useCreateFinancialEffectMutation();
  const updateFinancialEffectMutation = useUpdateFinancialEffectMutation();
  const deleteFinancialEffectMutation = useDeleteFinancialEffectMutation();
  const updateFinancialEffectIROStateMutation = useUpdateFinancialEffectIROStateMutation();

  const handleCreateFinancialEffect = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => {
    createFinancialEffectMutation.reset();
    setShowCreateFinancialEffectDialog({
      open: true,
      category,
      esrsTopic,
    });
  };
  const handleCloseCreateFinancialEffectDialog = async (
    data: IBaseDMAFinancialEffectData | null,
  ) => {
    // Handle create data
    if (data) {
      await createFinancialEffectMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showCreateFinancialEffectDialog.esrsTopic!.id,
        dmaCategoryId: showCreateFinancialEffectDialog.category!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowCreateFinancialEffectDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleEditFinancialEffect = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
    effect: IDMAFinancialEffect,
  ) => {
    updateFinancialEffectMutation.reset();
    updateFinancialEffectIROStateMutation.reset();
    setShowEditFinancialEffectDialog({
      open: true,
      category,
      esrsTopic,
      effectOrImpact: effect,
    });
  };
  const handleCloseEditFinancialEffectDialog = async (data: IBaseDMAFinancialEffectData | null) => {
    // Handle Update data
    if (data) {
      await updateFinancialEffectMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showEditFinancialEffectDialog.esrsTopic!.id,
        dmaCategoryId: showEditFinancialEffectDialog.category!.id,
        financialEffectId: showEditFinancialEffectDialog.effectOrImpact!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowEditFinancialEffectDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleDeleteFinancialEffect = () => {
    deleteFinancialEffectMutation.reset();
    setShowDeleteFinancialEffectDialog({
      open: true,
      category: showEditFinancialEffectDialog.category,
      esrsTopic: showEditFinancialEffectDialog.esrsTopic,
      effectOrImpact: showEditFinancialEffectDialog.effectOrImpact,
    });
  };
  const handleCloseDeleteFinancialEffectDialog = async (confirm: boolean) => {
    if (confirm) {
      await deleteFinancialEffectMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showDeleteFinancialEffectDialog.esrsTopic!.id,
        dmaCategoryId: showDeleteFinancialEffectDialog.category!.id,
        financialEffectId: showDeleteFinancialEffectDialog.effectOrImpact!.id,
      });

      // Close Edit Dialog after delete
      setShowEditFinancialEffectDialog((state) => ({
        ...state,
        open: false,
      }));
    }
    // Close Dialog
    setShowDeleteFinancialEffectDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleUpdateFinancialEffectIROState = async (newState: IIROState) => {
    const updatedEffect = await updateFinancialEffectIROStateMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId: selectedESRSTopic?.id ?? "",
      dmaCategoryId: showEditFinancialEffectDialog.category!.id,
      financialEffectId: showEditFinancialEffectDialog.effectOrImpact!.id,
      iroState: newState,
    });

    setShowEditFinancialEffectDialog((state) => ({ ...state, effectOrImpact: updatedEffect }));
  };

  // Topic Opt Out

  const [showOptOutOfTopicDialog, setShowOptOutOfTopicDialog] = useState<ITopicOptOutDialogState>({
    open: false,
    esrsTopic: null,
  });
  const optOutOfESRSTopicMutation = useOptOutOfESRSTopicMutation();

  const handleOptOutOfESRSTopic = (esrsTopic: IESRSTopic) => {
    optOutOfESRSTopicMutation.reset();
    setShowOptOutOfTopicDialog({
      open: true,
      esrsTopic,
    });
  };
  const handleCloseOptOutConfirmDialog = async (data: IOptOutOfDmaCategoryRequest | null) => {
    if (data) {
      await optOutOfESRSTopicMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showOptOutOfTopicDialog.esrsTopic!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowOptOutOfTopicDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  // Category Opt Out

  const [showCategoryOptOutDialog, setShowCategoryOptOutDialog] =
    useState<ICategoryOptInOptOutDialogState>({
      open: false,
      esrsTopic: null,
      category: null,
    });
  const handleOptOutOfCategory = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => {
    optOutOfDMACategoryMutation.reset();
    setShowCategoryOptOutDialog({
      open: true,
      esrsTopic,
      category,
    });
  };

  const optOutOfDMACategoryMutation = useOptOutDMACategoryMutation();

  const handleCloseCategoryOptOutDialog = async (data: IOptOutOfDmaCategoryRequest | null) => {
    if (data) {
      await optOutOfDMACategoryMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showCategoryOptOutDialog.esrsTopic!.id,
        dmaCategoryId: showCategoryOptOutDialog.category!.id,
        payload: data,
      });
    }
    // Close Dialog
    setShowCategoryOptOutDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  // Opt back into Category

  const [showCategoryOptBackInDialog, setShowCategoryOptBackInDialog] =
    useState<ICategoryOptInOptOutDialogState>({
      open: false,
      esrsTopic: null,
      category: null,
    });
  const handleOptBackIntoCategory = (
    esrsTopic: IESRSTopic,
    category: IDMACategoryWithEffectsAndChildren,
  ) => {
    optOutOfDMACategoryMutation.reset();
    setShowCategoryOptBackInDialog({
      open: true,
      esrsTopic,
      category,
    });
  };

  const handleCloseConfirmOptBackIntoCategoryDialog = async (data: IDMACategory | null) => {
    if (data) {
      await optOutOfDMACategoryMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId: showCategoryOptBackInDialog.esrsTopic!.id,
        dmaCategoryId: showCategoryOptBackInDialog.category!.id,
        payload: {
          optOut: false,
          optOutReason: undefined,
        },
      });
    }
    setShowCategoryOptBackInDialog((state) => ({
      ...state,
      open: false,
    }));
  };

  // DMA Categories - Start Data Recording

  const startDataRecordingMutation = useInitDMAForTopicMutation();

  const handleStartDataRecording = async (esrsTopic: IESRSTopic) => {
    if (!esrsTopic.currentTemplate) {
      console.error(
        "No current template available for ESRS Topic! handleStartDataRecording should not be called in this state!",
        esrsTopic,
      );
      return;
    }

    await startDataRecordingMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId: esrsTopic.id,
    });
  };

  // Add DMA Categories

  const {
    dmaCategoryEditingDialogsState,
    openCreateDMACategoryDialog,
    openEditDMACategoryDialog,
    closeDMACategoryDialogs,
  } = useDMACategoryEditingDialogsState();

  const handleCompleteDMACategoryAction = useCallback(
    (metaData: ICategoryCompleteActionMetadata) => {
      if (
        metaData.createdCategory &&
        !metaData.createdCategory.parentId && // Validate is top level category
        metaData.createdCategory.recordedTopicESRSTopicId === selectedESRSTopic?.id
      ) {
        setViewSettings(selectedESRSTopic?.id ?? null, metaData.createdCategory.id);
      }
      // Close all Dialogs
      closeDMACategoryDialogs();
    },
    [selectedESRSTopic, setViewSettings, closeDMACategoryDialogs],
  );

  // Complete DMA

  const esrsTopicsStates = useMemo(() => {
    return shownTopics.map(DMAUtilities.getESRSTopicState);
  }, [shownTopics]);

  const dmaCategoriesStates = useMemo(() => {
    return (
      selectedESRSTopic?.recordedESRSTopic?.dmaCategories.map(
        DMACategoryUtilities.getCategoryTotalState,
      ) ?? []
    );
  }, [selectedESRSTopic]);

  const canCompleteDMA = useMemo(
    () =>
      esrsTopics.length === esrsTopicsStates.length &&
      esrsTopicsStates.every((state) => state === IDMACategoryState.Verified),
    [esrsTopics, esrsTopicsStates],
  );

  const [showExecuteDMADialog, setShowExecuteDMADialog] = useState(false);
  const executeDMAMutation = useExecuteDMAMutation(async (variables) => {
    // Navigate back to Recording Period Dashboard after DMA is completed
    navigate(
      `/organizations/${variables.organizationId}/recording-periods/${variables.recordingPeriodId}/`,
    );
  });

  const handleCompleteDMA = () => {
    executeDMAMutation.reset();
    // Button would be disabled if DMA is not complete --> just show the dialog
    setShowExecuteDMADialog(true);
  };
  const handleCloseConfirmExecuteDMADialog = async (confirm: boolean) => {
    if (confirm) {
      await executeDMAMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
      });
    }
    // Close Dialog
    setShowExecuteDMADialog(false);
  };

  // Feedback Dialog

  const [stakeholderFeedbackDialogState, setStakeholderFeedbackDialogState] =
    useState<IStakeholderFeedbackDialogState>({ open: false, esrsTopic: null, dmaCategory: null });

  const handleOpenStakeholderFeedbackDialog = (
    esrsTopic: IESRSTopic,
    dmaCategory: IDMACategoryWithEffectsAndChildren,
  ) => {
    setStakeholderFeedbackDialogState({
      open: true,
      esrsTopic,
      dmaCategory,
    });
  };

  const handleCloseStakeholderFeedbackDialog = () => {
    setStakeholderFeedbackDialogState((state) => ({ ...state, open: false }));
  };

  // Sync dmaCategory shown in Dialog (on update)
  useEffect(() => {
    if (stakeholderFeedbackDialogState.open) {
      const newTopic = esrsTopics.find(
        (t) => t.id === stakeholderFeedbackDialogState.esrsTopic?.id,
      );
      if (!newTopic) {
        return;
      }

      const newDmaCategory = DMAUtilities.findCategoryInTopic(
        newTopic,
        stakeholderFeedbackDialogState.dmaCategory?.id ?? "",
      );
      if (!newDmaCategory) {
        return;
      }

      setStakeholderFeedbackDialogState((state) => ({
        ...state,
        esrsTopic: newTopic,
        dmaCategory: newDmaCategory,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esrsTopics]);

  // Render Component

  const isLoading = useMemo(
    () => startDataRecordingMutation.isPending,
    [startDataRecordingMutation.isPending],
  );

  const renderName = useRenderDMACategoryName();
  const renderTopicName = useRenderESRSTopicName();

  return (
    <>
      {/* Material Impacts Modals */}
      <DMAMaterialImpactEditDialog
        organizationId={organizationId}
        open={showCreateMaterialImpactDialog.open}
        dmaCategory={showCreateMaterialImpactDialog.category}
        dmaConfiguration={dmaConfiguration}
        dataEntryObject={dataEntryObject}
        loading={createMaterialImpactMutation.isPending}
        disabled={createMaterialImpactMutation.isPending}
        error={createMaterialImpactMutation.isError ? createMaterialImpactMutation.error : null}
        onClose={handleCloseCreateMaterialImpactDialog}
        esrsTopic={selectedESRSTopic}
      />
      <DMAMaterialImpactEditDialog
        organizationId={organizationId}
        open={showEditMaterialImpactDialog.open}
        materialImpact={showEditMaterialImpactDialog.effectOrImpact}
        dmaCategory={showEditMaterialImpactDialog.category}
        dmaConfiguration={dmaConfiguration}
        dataEntryObject={dataEntryObject}
        loading={
          updateMaterialImpactMutation.isPending || updateMaterialImpactIROStateMutation.isPending
        }
        disabled={
          updateMaterialImpactMutation.isPending || updateMaterialImpactIROStateMutation.isPending
        }
        error={
          updateMaterialImpactMutation.isError
            ? updateMaterialImpactMutation.error
            : updateMaterialImpactIROStateMutation.isError
            ? updateMaterialImpactIROStateMutation.error
            : null
        }
        onClose={handleCloseUpdateMaterialImpactDialog}
        onUpdateIROState={handleUpdateMaterialImpactIROState}
        onDelete={handleDeleteMaterialImpact}
        esrsTopic={selectedESRSTopic}
      />
      <DeleteImpactOrEffectConfirmDialog
        open={showDeleteMaterialImpactDialog.open}
        loading={deleteMaterialImpactMutation.isPending}
        disabled={deleteMaterialImpactMutation.isPending}
        error={deleteMaterialImpactMutation.isError ? deleteMaterialImpactMutation.error : null}
        type="material_impact"
        effectOrImpact={showDeleteMaterialImpactDialog.effectOrImpact}
        onCancel={() => handleCloseDeleteMaterialImpactDialog(false)}
        onConfirm={() => handleCloseDeleteMaterialImpactDialog(true)}
      />

      {/* Financial Effects Modals */}
      <DMAFinancialEffectEditDialog
        organizationId={organizationId}
        open={showCreateFinancialEffectDialog.open}
        dmaConfiguration={dmaConfiguration}
        dmaCategory={showCreateFinancialEffectDialog.category}
        dataEntryObject={dataEntryObject}
        loading={createFinancialEffectMutation.isPending}
        disabled={createFinancialEffectMutation.isPending}
        error={createFinancialEffectMutation.isError ? createFinancialEffectMutation.error : null}
        onClose={handleCloseCreateFinancialEffectDialog}
        recordingPeriodId={recordingPeriodId}
        esrsTopic={selectedESRSTopic}
      />
      <DMAFinancialEffectEditDialog
        organizationId={organizationId}
        open={showEditFinancialEffectDialog.open}
        financialEffect={showEditFinancialEffectDialog.effectOrImpact}
        dmaConfiguration={dmaConfiguration}
        dmaCategory={showEditFinancialEffectDialog.category}
        dataEntryObject={dataEntryObject}
        loading={
          updateFinancialEffectMutation.isPending || updateFinancialEffectIROStateMutation.isPending
        }
        disabled={
          updateFinancialEffectMutation.isPending || updateFinancialEffectIROStateMutation.isPending
        }
        error={
          updateFinancialEffectMutation.isError
            ? updateFinancialEffectMutation.error
            : updateFinancialEffectIROStateMutation.isError
            ? updateFinancialEffectIROStateMutation.error
            : null
        }
        onClose={handleCloseEditFinancialEffectDialog}
        onUpdateIROState={handleUpdateFinancialEffectIROState}
        onDelete={handleDeleteFinancialEffect}
        recordingPeriodId={recordingPeriodId}
        esrsTopic={selectedESRSTopic}
      />
      <DeleteImpactOrEffectConfirmDialog
        open={showDeleteFinancialEffectDialog.open}
        loading={deleteFinancialEffectMutation.isPending}
        disabled={deleteFinancialEffectMutation.isPending}
        error={deleteFinancialEffectMutation.isError ? deleteFinancialEffectMutation.error : null}
        type="financial_effect"
        effectOrImpact={showDeleteFinancialEffectDialog.effectOrImpact}
        onCancel={() => handleCloseDeleteFinancialEffectDialog(false)}
        onConfirm={() => handleCloseDeleteFinancialEffectDialog(true)}
      />

      {/* Topic Opt Out Dialog */}
      <EsrsTopicOptOutDialog
        open={showOptOutOfTopicDialog.open}
        loading={optOutOfESRSTopicMutation.isPending}
        error={optOutOfESRSTopicMutation.isError ? optOutOfESRSTopicMutation.error : null}
        disabled={optOutOfESRSTopicMutation.isPending}
        esrsTopic={showOptOutOfTopicDialog.esrsTopic}
        onClose={handleCloseOptOutConfirmDialog}
      />

      {/* Category Opt Out Dialog */}
      <DMACategoryOptOutDialog
        open={showCategoryOptOutDialog.open}
        category={showCategoryOptOutDialog.category}
        loading={optOutOfDMACategoryMutation.isPending}
        disabled={optOutOfDMACategoryMutation.isPending}
        error={optOutOfDMACategoryMutation.isError ? optOutOfDMACategoryMutation.error : null}
        onClose={handleCloseCategoryOptOutDialog}
      />
      <DMACategoryOptBackInConfirmDialog
        open={showCategoryOptBackInDialog.open}
        category={showCategoryOptBackInDialog.category}
        loading={optOutOfDMACategoryMutation.isPending}
        disabled={optOutOfDMACategoryMutation.isPending}
        error={optOutOfDMACategoryMutation.isError ? optOutOfDMACategoryMutation.error : null}
        onClose={handleCloseConfirmOptBackIntoCategoryDialog}
      />

      {/* DMA Category Dialogs */}
      <DMACategoryDialogs
        organizationId={organizationId}
        dataEntryObjectId={dataEntryObjectId}
        recordingPeriodId={recordingPeriodId}
        dmaCategoryDialogsState={dmaCategoryEditingDialogsState}
        onCompleteAction={handleCompleteDMACategoryAction}
      />

      <DMACategoryFeedbackDialog
        open={stakeholderFeedbackDialogState.open}
        esrsTopic={stakeholderFeedbackDialogState.esrsTopic}
        dmaCategory={stakeholderFeedbackDialogState.dmaCategory}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        esrsTopicId={stakeholderFeedbackDialogState.esrsTopic?.id ?? ""}
        onClose={handleCloseStakeholderFeedbackDialog}
      />

      {/* Execute DMA Dialogs */}
      <ExecuteDMAConfirmDialog
        open={showExecuteDMADialog}
        loading={executeDMAMutation.isPending}
        disabled={executeDMAMutation.isPending}
        error={executeDMAMutation.isError ? executeDMAMutation.error : null}
        onClose={handleCloseConfirmExecuteDMADialog}
      />

      {/* Error dialog for startDataRecording mutation */}
      <ErrorDialog
        open={startDataRecordingMutation.isError}
        onClose={() => {
          startDataRecordingMutation.reset();
        }}
        error={startDataRecordingMutation.error ?? undefined}
      />

      {/* Content */}
      <Box flex={1} display="flex">
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <Box position="relative" flex={1} display="flex">
            <Box display="flex" maxHeight="100vh" flex={1}>
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                width="18vw"
                maxWidth={340}
                // sx={{ borderRight: "1px solid", borderColor: (theme) => theme.palette.divider }}
              >
                <Box sx={{ overflowY: "auto", overflowX: "clip" }} py={2} px="1.5vw">
                  <Stepper nonLinear activeStep={selectedStepperIndex} orientation="vertical">
                    {/* DMA Configuration */}
                    <Step completed={selectedStepperIndex > 0}>
                      <StepButton
                        onClick={() => {
                          // be sure to clear saved category
                          clearSettingsForDataEntryObject(dataEntryObjectId);
                          // set correct index
                          handleChangeStepperIndex(0);
                        }}
                        sx={{ flex: 1 }}
                      >
                        <StepLabel sx={{ flex: 1 }}>
                          <Box display="flex" gap={2}>
                            <Box flex={1}>{t("label_step_dma_configuration")}</Box>
                          </Box>
                        </StepLabel>
                      </StepButton>
                    </Step>

                    {/* Topics Steps */}
                    {shownTopics.map((esrsTopic, index) => (
                      <Step
                        key={esrsTopic.id}
                        completed={esrsTopicsStates[index] === IDMACategoryState.Verified}
                      >
                        <StepButton
                          onClick={() => handleChangeStepperIndex(index + 1)}
                          sx={{ flex: 1 }}
                        >
                          <StepLabel sx={{ flex: 1 }}>
                            <Box>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="inherit">{esrsTopic.topic}</Typography>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  ml="auto"
                                >
                                  {esrsTopicsStates[index] === IDMACategoryState.Open && (
                                    <Tooltip title={t("dma_open_topic")}>
                                      <PriorityHigh color="warning" />
                                    </Tooltip>
                                  )}
                                  {esrsTopicsStates[index] === IDMACategoryState.Shared && (
                                    <Tooltip title={t("dma_shared_topic")}>
                                      <Done color="primary" />
                                    </Tooltip>
                                  )}
                                  {esrsTopicsStates[index] === IDMACategoryState.Verified && (
                                    <Tooltip title={t("dma_verified_topic")}>
                                      <DoneAll color="success" />
                                    </Tooltip>
                                  )}
                                </Box>
                              </Box>
                              <Typography variant="caption">
                                {renderTopicName(esrsTopic)}
                              </Typography>
                            </Box>
                          </StepLabel>
                        </StepButton>
                        {esrsTopic.recordedESRSTopic &&
                          esrsTopic.recordedESRSTopic?.dmaCategories.length > 0 && (
                            <Collapse in={viewedESRSTopicId === esrsTopic.id}>
                              <Box
                                ml={1.5}
                                pl={1.5}
                                pt={1}
                                mb={-1}
                                borderLeft="solid 1px"
                                borderColor="#bdbdbd"
                              >
                                {/* List top level categories */}
                                {esrsTopic.recordedESRSTopic?.dmaCategories.map(
                                  (dmaCategory, catIndex) => (
                                    <Box
                                      key={dmaCategory.id}
                                      onClick={() => setViewSettings(esrsTopic.id, dmaCategory.id)}
                                      sx={{ cursor: "pointer" }}
                                      display="flex"
                                      alignItems="start"
                                      py={1}
                                    >
                                      <Typography
                                        variant="body2"
                                        fontWeight={
                                          dmaCategory.id === viewedDMACategoryId ? "bold" : "light"
                                        }
                                        flex={1}
                                      >
                                        {renderName(dmaCategory)}
                                      </Typography>
                                      <DMACategoryStatusIndicator
                                        state={dmaCategoriesStates[catIndex]}
                                        fontSize="medium"
                                      />
                                    </Box>
                                  ),
                                )}
                                {/* Add Top Level Category Button */}
                                <Box display="flex" flexDirection="column" mt={1}>
                                  <Tooltip title={t("button_create_category")} placement="right">
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() => openCreateDMACategoryDialog(esrsTopic)}
                                    >
                                      <PlusIcon />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Collapse>
                          )}
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
              <Box flex={1} sx={{ overflowY: "auto", overflowX: "clip" }} py={2} px="4vw">
                {/* Title */}
                <Typography variant="h1" mb={3}>
                  {selectedESRSTopic
                    ? `${selectedESRSTopic.topic} - ${renderTopicName(selectedESRSTopic)}`
                    : t("title_dma_configuration")}
                </Typography>

                {/* DMA Configuration */}
                {!selectedESRSTopic && (
                  <DMAConfiguration
                    organizationId={organizationId}
                    recordingPeriodId={recordingPeriodId}
                    dataEntryObjectId={dataEntryObjectId}
                    dmaConfiguration={dmaConfiguration}
                  />
                )}

                {/* ESRS Topic Content */}
                {selectedESRSTopic && (
                  <ESRSTopicComponent
                    organizationId={organizationId}
                    recordingPeriodId={recordingPeriodId}
                    dataEntryObjectId={dataEntryObjectId}
                    esrsTopic={selectedESRSTopic}
                    showMaterialImpacts={showGroups.showMaterialImpacts}
                    showFinancialEffects={showGroups.showFinancialEffects}
                    showEmpty={!filterByUserIds.length} // Hide when filtered by user
                    onlyViewCategory={selectedDMACategory}
                    onCreateMaterialImpact={handleCreateMaterialImpact}
                    onCreateFinancialEffect={handleCreateFinancialEffect}
                    onEditMaterialImpact={handleUpdateMaterialImpact}
                    onEditFinancialEffect={handleEditFinancialEffect}
                    onOptOutOfRecordingESRSTopic={handleOptOutOfESRSTopic}
                    onStartDataRecordingForESRSTopic={handleStartDataRecording}
                    onEditOptOutReasonOfESRSTopic={handleOptOutOfESRSTopic}
                    onEditDMACategory={openEditDMACategoryDialog}
                    onCreateDMACategory={openCreateDMACategoryDialog}
                    onAddChildDMACategory={(esrsTopic, parentCategory) =>
                      openCreateDMACategoryDialog(esrsTopic, parentCategory.id)
                    }
                    onOptOutOfDMACategory={handleOptOutOfCategory}
                    onEditOptOutOfDMACategory={handleOptOutOfCategory}
                    onOptBackIntoDMACategory={handleOptBackIntoCategory}
                    onOpenFeedbackDialog={handleOpenStakeholderFeedbackDialog}
                  />
                )}

                {/* Spacer for Action Bar */}
                <ESRSTopicsStepperFloatingActionBarComponentSpacer />
              </Box>
            </Box>
            {/* Floating Action Bar */}
            <ESRSTopicsStepperFloatingActionBarComponent
              organizationId={organizationId}
              recordingPeriodId={recordingPeriodId}
              showGroups={showGroups}
              setShowGroups={setShowGroups}
              esrsTopics={shownTopics}
              selectedESRSTopic={selectedESRSTopic}
              selectedDMACategory={selectedDMACategory}
              canCompleteDMA={canCompleteDMA}
              onChangeViewSettings={setViewSettings}
              onComplete={handleCompleteDMA}
              filterableUserIds={filterableUserIds}
              filterByUserIds={filterByUserIds}
              onChangeFilterByUserIds={setFilterByUserIds}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
