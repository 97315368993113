import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ICreateSourceFilesRequest,
  ISourceData,
  ISourceFile,
  ISourceFileState,
} from "@netcero/netcero-core-api-client";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialogTextBody } from "../common/dialogs/variants/confirm.dialog";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { DeleteIcon, DownloadIcon, UploadIcon } from "../common/constants/tabler-icon.constants";
import { useDialogState } from "../common/dialogs/dialog-state.hook";
import { DialogCloseButton } from "../common/dialogs/dialog-button.components";

interface IUploadDialogComponentProps {
  open: boolean;
  createSourceFilePending?: boolean;
  deleteSourceFilePending?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: () => void;
  text: {
    buttons: {
      close: ReactNode;
    };
  };
  onFileDelete: (sourceFile: ISourceFile) => Promise<void>;
  onMultipleFileUpload: (sourceFiles: FileList) => void;
  source: ISourceData;
  handleFileDownload: (sourceFile: ISourceFile) => void;
}

export const multipleFileToCreateSourceFilesRequest = (
  files: FileList,
): ICreateSourceFilesRequest => {
  const fileListArray = Array.from(files);
  return {
    files: fileListArray.map((file) => ({
      fileName: file.name,
      mimeType: file.type,
    })),
  };
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const UploadDialogComponent: FC<IUploadDialogComponentProps> = ({
  open,
  createSourceFilePending,
  deleteSourceFilePending,
  error,
  disabled,
  onClose,
  text,
  onFileDelete,
  onMultipleFileUpload,
  source,
  handleFileDownload,
}) => {
  const { t } = useTranslation("sources_files_dialog");

  const {
    isOpen: isDeleteFileDialogOpen,
    openDialog: openDeleteFileDialog,
    closeDialog: closeDeleteFileDialog,
    data: deleteFileDialogState,
  } = useDialogState<ISourceFile>();

  const noFilesUploaded = (source?.files ?? []).length === 0;

  const handleMultipleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onMultipleFileUpload(event.target.files);
    }
  };

  const handleDeleteFile = async (confirm: boolean) => {
    if (confirm && deleteFileDialogState !== undefined) {
      await onFileDelete(deleteFileDialogState);
    }
    closeDeleteFileDialog();
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md">
      <DialogTitle>{t("title")}</DialogTitle>
      {createSourceFilePending && <LinearProgress />}
      <DialogContent>
        {error && (
          <Box pb={1}>
            <ErrorTextComponent error={error} />
          </Box>
        )}
        {noFilesUploaded ? (
          <Typography>{t("no_files_uploaded")}</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {source.files.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2}>-</TableCell>
                </TableRow>
              )}
              {source.files.map((file: ISourceFile) => {
                return (
                  (file.state === ISourceFileState.UploadSuccessful ||
                    file.state === ISourceFileState.WaitingForUpload) && (
                    <TableRow key={file.id}>
                      <TableCell
                        sx={{
                          maxWidth: 500,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {file.fileName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right", textWrap: "nowrap" }}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleFileDownload(file);
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            openDeleteFileDialog(file);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
              <ConfirmDialogTextBody
                open={isDeleteFileDialogOpen}
                error={error}
                loading={deleteSourceFilePending}
                disabled={deleteSourceFilePending}
                onClose={handleDeleteFile}
                title={t("delete_title", { sourceName: source.name })}
                content={t("delete_body")}
              />
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mr: "auto" }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<UploadIcon />}
        >
          {t("upload_file")}
          <VisuallyHiddenInput
            type="file"
            multiple
            onChange={handleMultipleFileChange}
            disabled={disabled}
          />
        </Button>
        <DialogCloseButton onClick={() => onClose()} disabled={disabled}>
          {text.buttons.close}
        </DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};
