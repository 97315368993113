/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IBaseTargetPathGoalData } from "../models";
// @ts-ignore
import { ICloneTargetPath404Response } from "../models";
// @ts-ignore
import { ICreateTargetPathGoal409Response } from "../models";
// @ts-ignore
import { IDeleteTargetPathGoal404Response } from "../models";
// @ts-ignore
import { ITargetPathGoal } from "../models";
// @ts-ignore
import { IUpdateTargetPath409Response } from "../models";
// @ts-ignore
import { IUpdateTargetPathGoal404Response } from "../models";
/**
 * TargetPathGoalsApi - axios parameter creator
 * @export
 */
export const TargetPathGoalsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This endpoint creates a new target path goal for the given target path.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Create Target Path Goal Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTargetPathGoal: async (
      organizationId: string,
      targetPathId: string,
      iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createTargetPathGoal", "organizationId", organizationId);
      // verify required parameter 'targetPathId' is not null or undefined
      assertParamExists("createTargetPathGoal", "targetPathId", targetPathId);
      const localVarPath = `/organizations/{organizationId}/target-paths/{targetPathId}/goals`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"targetPathId"}}`, encodeURIComponent(String(targetPathId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseTargetPathGoalData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint deletes a given target path goal.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {string} targetPathGoalId The id of the target path goal to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTargetPathGoal: async (
      organizationId: string,
      targetPathId: string,
      targetPathGoalId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteTargetPathGoal", "organizationId", organizationId);
      // verify required parameter 'targetPathId' is not null or undefined
      assertParamExists("deleteTargetPathGoal", "targetPathId", targetPathId);
      // verify required parameter 'targetPathGoalId' is not null or undefined
      assertParamExists("deleteTargetPathGoal", "targetPathGoalId", targetPathGoalId);
      const localVarPath =
        `/organizations/{organizationId}/target-paths/{targetPathId}/goals/{targetPathGoalId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"targetPathId"}}`, encodeURIComponent(String(targetPathId)))
          .replace(`{${"targetPathGoalId"}}`, encodeURIComponent(String(targetPathGoalId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint updates the target path goal with the given id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {string} targetPathGoalId The id of the target path goal to use.
     * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Update Target Path Goal Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTargetPathGoal: async (
      organizationId: string,
      targetPathId: string,
      targetPathGoalId: string,
      iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateTargetPathGoal", "organizationId", organizationId);
      // verify required parameter 'targetPathId' is not null or undefined
      assertParamExists("updateTargetPathGoal", "targetPathId", targetPathId);
      // verify required parameter 'targetPathGoalId' is not null or undefined
      assertParamExists("updateTargetPathGoal", "targetPathGoalId", targetPathGoalId);
      const localVarPath =
        `/organizations/{organizationId}/target-paths/{targetPathId}/goals/{targetPathGoalId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"targetPathId"}}`, encodeURIComponent(String(targetPathId)))
          .replace(`{${"targetPathGoalId"}}`, encodeURIComponent(String(targetPathGoalId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseTargetPathGoalData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TargetPathGoalsApi - functional programming interface
 * @export
 */
export const TargetPathGoalsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TargetPathGoalsApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint creates a new target path goal for the given target path.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Create Target Path Goal Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTargetPathGoal(
      organizationId: string,
      targetPathId: string,
      iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITargetPathGoal>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTargetPathGoal(
        organizationId,
        targetPathId,
        iBaseTargetPathGoalData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint deletes a given target path goal.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {string} targetPathGoalId The id of the target path goal to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTargetPathGoal(
      organizationId: string,
      targetPathId: string,
      targetPathGoalId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTargetPathGoal(
        organizationId,
        targetPathId,
        targetPathGoalId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint updates the target path goal with the given id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {string} targetPathGoalId The id of the target path goal to use.
     * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Update Target Path Goal Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTargetPathGoal(
      organizationId: string,
      targetPathId: string,
      targetPathGoalId: string,
      iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITargetPathGoal>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTargetPathGoal(
        organizationId,
        targetPathId,
        targetPathGoalId,
        iBaseTargetPathGoalData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TargetPathGoalsApi - factory interface
 * @export
 */
export const TargetPathGoalsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TargetPathGoalsApiFp(configuration);
  return {
    /**
     * This endpoint creates a new target path goal for the given target path.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Create Target Path Goal Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTargetPathGoal(
      organizationId: string,
      targetPathId: string,
      iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
      options?: any,
    ): AxiosPromise<ITargetPathGoal> {
      return localVarFp
        .createTargetPathGoal(organizationId, targetPathId, iBaseTargetPathGoalData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint deletes a given target path goal.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {string} targetPathGoalId The id of the target path goal to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTargetPathGoal(
      organizationId: string,
      targetPathId: string,
      targetPathGoalId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteTargetPathGoal(organizationId, targetPathId, targetPathGoalId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint updates the target path goal with the given id.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} targetPathId The id of the target path to use.
     * @param {string} targetPathGoalId The id of the target path goal to use.
     * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Update Target Path Goal Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTargetPathGoal(
      organizationId: string,
      targetPathId: string,
      targetPathGoalId: string,
      iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
      options?: any,
    ): AxiosPromise<ITargetPathGoal> {
      return localVarFp
        .updateTargetPathGoal(
          organizationId,
          targetPathId,
          targetPathGoalId,
          iBaseTargetPathGoalData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TargetPathGoalsApi - interface
 * @export
 * @interface TargetPathGoalsApi
 */
export interface TargetPathGoalsApiInterface {
  /**
   * This endpoint creates a new target path goal for the given target path.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} targetPathId The id of the target path to use.
   * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Create Target Path Goal Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TargetPathGoalsApiInterface
   */
  createTargetPathGoal(
    organizationId: string,
    targetPathId: string,
    iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ITargetPathGoal>;

  /**
   * This endpoint deletes a given target path goal.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} targetPathId The id of the target path to use.
   * @param {string} targetPathGoalId The id of the target path goal to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TargetPathGoalsApiInterface
   */
  deleteTargetPathGoal(
    organizationId: string,
    targetPathId: string,
    targetPathGoalId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint updates the target path goal with the given id.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} targetPathId The id of the target path to use.
   * @param {string} targetPathGoalId The id of the target path goal to use.
   * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Update Target Path Goal Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TargetPathGoalsApiInterface
   */
  updateTargetPathGoal(
    organizationId: string,
    targetPathId: string,
    targetPathGoalId: string,
    iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ITargetPathGoal>;
}

/**
 * TargetPathGoalsApi - object-oriented interface
 * @export
 * @class TargetPathGoalsApi
 * @extends {BaseAPI}
 */
export class TargetPathGoalsApi extends BaseAPI implements TargetPathGoalsApiInterface {
  /**
   * This endpoint creates a new target path goal for the given target path.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} targetPathId The id of the target path to use.
   * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Create Target Path Goal Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TargetPathGoalsApi
   */
  public createTargetPathGoal(
    organizationId: string,
    targetPathId: string,
    iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
    options?: AxiosRequestConfig,
  ) {
    return TargetPathGoalsApiFp(this.configuration)
      .createTargetPathGoal(organizationId, targetPathId, iBaseTargetPathGoalData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint deletes a given target path goal.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} targetPathId The id of the target path to use.
   * @param {string} targetPathGoalId The id of the target path goal to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TargetPathGoalsApi
   */
  public deleteTargetPathGoal(
    organizationId: string,
    targetPathId: string,
    targetPathGoalId: string,
    options?: AxiosRequestConfig,
  ) {
    return TargetPathGoalsApiFp(this.configuration)
      .deleteTargetPathGoal(organizationId, targetPathId, targetPathGoalId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint updates the target path goal with the given id.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} targetPathId The id of the target path to use.
   * @param {string} targetPathGoalId The id of the target path goal to use.
   * @param {IBaseTargetPathGoalData} [iBaseTargetPathGoalData] Update Target Path Goal Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TargetPathGoalsApi
   */
  public updateTargetPathGoal(
    organizationId: string,
    targetPathId: string,
    targetPathGoalId: string,
    iBaseTargetPathGoalData?: IBaseTargetPathGoalData,
    options?: AxiosRequestConfig,
  ) {
    return TargetPathGoalsApiFp(this.configuration)
      .updateTargetPathGoal(
        organizationId,
        targetPathId,
        targetPathGoalId,
        iBaseTargetPathGoalData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
