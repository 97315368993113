import { FC, useCallback, useMemo, useState } from "react";
import {
  IDisclosureRequirementAssessment,
  IDisclosureRequirementAssessmentData,
  IDisclosureRequirementForAssessment,
} from "@netcero/netcero-core-api-client";
import { Box, Fade, Tab, Tabs, Typography } from "@mui/material";
import { DrEggOverviewTable } from "./dr-egg-overview-table.component";
import { DrAssessmentUtilities } from "../dr-assessment.utilities";
import { useUpdateDrAssessmentMutation } from "../dr-assessment.mutations";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import {
  ESRSTopicIdentifier,
  ESRSTopicUtilities,
  ORDERED_ESRS_TOPICS,
} from "@netcero/netcero-common";
import { useRenderESRSTopicNameForIdentifier } from "../../double-materiality-assessment/hooks/render-esrs-topic-name-for-identifier.hook";

interface IDrEggOverviewProps {
  organizationId: string;
  drsForAssessment: IDisclosureRequirementForAssessment[];
  assessments: IDisclosureRequirementAssessment[];
}

export const DrEggOverview: FC<IDrEggOverviewProps> = ({
  organizationId,
  drsForAssessment,
  assessments,
}) => {
  const renderESRSTopicName = useRenderESRSTopicNameForIdentifier();
  const [expandedDrIds, setExpandedDrIds] = useState<string[]>([]);
  const onChangeDrExpansion = useCallback((drId: string) => {
    setExpandedDrIds((prev) =>
      prev.includes(drId) ? prev.filter((id) => id !== drId) : [...prev, drId],
    );
  }, []);

  const updateDrAssessmentMutation = useUpdateDrAssessmentMutation();
  const { wrapApiPromise } = useAppSnackbar();
  const updateDrAssessment = useCallback(
    async (disclosureRequirementId: string, values: IDisclosureRequirementAssessmentData) => {
      await wrapApiPromise(
        updateDrAssessmentMutation.mutateAsync({
          organizationId,
          disclosureRequirementId,
          payload: values,
        }),
        { type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_ASSESSMENT },
      );
    },
    [organizationId, updateDrAssessmentMutation, wrapApiPromise],
  );

  const tableData = useMemo(
    () => DrAssessmentUtilities.generateTableDrList(drsForAssessment, assessments),
    [drsForAssessment, assessments],
  );

  const valuesInUse = useMemo(
    () => DrAssessmentUtilities.getUsedAssessmentValues(assessments),
    [assessments],
  );

  const topicsToDisplay = useMemo(
    () => ORDERED_ESRS_TOPICS.filter((topic) => tableData[topic].length > 0),
    [tableData],
  );
  const [viewedTopic, setViewedTopic] = useState<ESRSTopicIdentifier>(topicsToDisplay[0]);

  return (
    <Box>
      <Tabs variant="fullWidth" value={viewedTopic}>
        {topicsToDisplay.map((topic) => (
          <Tab
            key={topic}
            value={topic}
            label={<span>{ESRSTopicUtilities.convertTopicToDisplayTopic(topic)}</span>}
            onClick={() => setViewedTopic(topic)}
            sx={{
              backgroundColor: topic === viewedTopic ? "#00000008" : undefined,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        ))}
      </Tabs>
      <Fade key={viewedTopic} in appear>
        <Box>
          <Typography variant="h4" component="h2" mt={2}>
            {ESRSTopicUtilities.convertTopicToDisplayTopic(viewedTopic)} -{" "}
            {renderESRSTopicName(viewedTopic)}
          </Typography>
          <DrEggOverviewTable
            rows={tableData[viewedTopic]}
            valuesInUse={valuesInUse}
            expandedDrIds={expandedDrIds}
            onChangeDrExpansion={onChangeDrExpansion}
            onSaveAssessment={updateDrAssessment}
            // Purposefully left out for now since it messes with the UX
            // disabled={updateDrAssessmentMutation.isPending}
          />
        </Box>
      </Fade>
    </Box>
  );
};
