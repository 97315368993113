import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  LinearProgress,
  styled,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMACategoryWithEffectsAndChildren,
  IStakeholderFeedback,
  ISubmitStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { DMASliderValues } from "@netcero/netcero-dma";
import { FC, useCallback, useEffect, useMemo } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { DMASlider } from "../../double-materiality-assessment/common/dma-slider.component";
import { DMAFormatUtilities } from "../../double-materiality-assessment/utilities/dma-format.utilities";
import { useRenderDMACategoryName } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import {
  DialogCancelButton,
  DialogSaveButton,
} from "../../common/dialogs/dialog-button.components";

const SliderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: { flexWrap: "wrap" },
  [theme.breakpoints.up("md")]: { flexWrap: "nowrap" },
  paddingRight: theme.spacing(4),
}));

const MIN_WIDTH_SLIDER_LABEL = 240;

interface IStakeholderFeedbackDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (
    data: { categoryId: string; feedback: ISubmitStakeholderFeedbackRequest } | null,
  ) => void;

  // data for dialog
  category: Pick<
    IDMACategoryWithEffectsAndChildren,
    "name" | "id" | "materiality" | "optOutReason"
  > | null;
  feedback: IStakeholderFeedback | null;
  createMode: boolean;
}

interface IInternalStakeholderFeedback
  extends Omit<Required<ISubmitStakeholderFeedbackRequest>, "feedbackType"> {
  diverge: boolean;
}

const getDefaultValuesFromFeedback = (
  feedback: IStakeholderFeedback | null,
): IInternalStakeholderFeedback => ({
  feedback: feedback?.feedback || "",
  assessment: feedback?.assessment || DMASliderValues.Average,
  agreed: feedback?.agreed ?? false,
  diverge: feedback?.assessment !== undefined,
});

const useIsDirty = (
  fields: UseFormReturn<IInternalStakeholderFeedback>["formState"]["dirtyFields"],
  initiallyDiverged: boolean,
) => {
  return useMemo(() => {
    const modified = { ...fields };
    // don't consider the assessment field if diverge is not set
    // However, if the assessment was initially diverged, it should be considered as changed
    if (!fields.diverge && !initiallyDiverged) {
      delete modified.assessment;
    }
    return Object.values(modified).length > 0;
    // "fields" is a reference, therefore changes to it are not picked up by the memo
    // solution: observe changes to each field separately
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.diverge, fields.feedback, fields.agreed, fields.assessment]);
};

export const StakeholderFeedbackDialog: FC<IStakeholderFeedbackDialogProps> = ({
  open,
  loading,
  error,
  disabled,
  feedback,
  category,
  createMode,
  onClose,
}) => {
  const { t } = useTranslation("stakeholder_feedback_dialog");

  const {
    control,
    formState: { dirtyFields, errors, defaultValues },
    watch,
    handleSubmit,
    reset,
  } = useForm<IInternalStakeholderFeedback>({
    defaultValues: getDefaultValuesFromFeedback(feedback),
    resolver: (values, context) => {
      if (!values.agreed) {
        if (!values.feedback.trim() && !values.diverge) {
          return {
            values: {},
            errors: {
              feedback: {
                type: "validate",
                message: t("error_at_least_one_form_of_feedback_required"),
              },
              diverge: {
                type: "validate",
                message: t("error_at_least_one_form_of_feedback_required"),
              },
            },
          };
        }
      }
      return {
        values,
        errors: {},
      };
    },
  });

  // be sure to reset the form when the dialog is opened
  useEffect(() => {
    if (open) {
      reset(getDefaultValuesFromFeedback(feedback));
    }
  }, [reset, open, feedback]);

  const isDirty = useIsDirty(dirtyFields, defaultValues!.diverge!);

  const handleEmit = useCallback(
    (data: IInternalStakeholderFeedback) => {
      // should never happen, just to prevent null pointer in case it ever does
      if (!feedback || !category) {
        return;
      }
      const result: ISubmitStakeholderFeedbackRequest = {
        feedbackType: feedback.feedbackType,
        // prevent empty strings from being submitted to API
        feedback: data.feedback.trim() || undefined,
        // make sure not to submit assessment if it's not diverged / the user doesn't agree to divergence
        assessment: data.diverge && !data.agreed ? data.assessment : undefined,
        agreed: data.agreed,
      };
      onClose({ categoryId: category.id, feedback: result });
    },
    [feedback, onClose, category],
  );

  const currentAgreed = watch("agreed");
  const currentDiverge = watch("diverge");

  const renderName = useRenderDMACategoryName();

  return (
    <Dialog
      open={open}
      onClose={!isDirty ? () => onClose(null) : undefined}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle display="flex" alignItems="center" gap={2}>
        {/* Title Text */}
        <Box flex={1} component="span">
          {feedback ? t(`title_${feedback.feedbackType}`, { name: renderName(category) }) : ""}
        </Box>
      </DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        {/* Materiality Info */}
        {category && feedback && (
          <>
            <StakeholderFeedbackDialogMaterialityInfoComponent
              mode="with-materiality"
              materiality={category.materiality}
              type={feedback.feedbackType}
              optOutReason={category.optOutReason ?? null}
            />
            <Divider orientation="horizontal" variant="fullWidth" />
          </>
        )}

        <Box display="flex" flexDirection="column" gap={2} py={1}>
          {/*  /!* Basic Properties Inputs *!/*/}
          {/* Checkbox for "agreed" */}
          <Controller
            control={control}
            name="agreed"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(evt, checked) => field.onChange(checked)}
                    disabled={disabled}
                  />
                }
                label={t("label_agreed")}
                disabled={disabled}
              />
            )}
          />

          {/* Switch to determine whether user wants to display slider when*/}
          {/* feedback is not agreed */}
          {!currentAgreed && (
            <Controller
              control={control}
              name="diverge"
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <FormControlLabel
                    label={t("label_add_divergent_assessment")}
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(event, checked) => field.onChange(checked)}
                      />
                    }
                  />
                  {error?.message && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          )}

          {/* Assessment Slider */}
          {!currentAgreed && currentDiverge && (
            <SliderContainer>
              <FormLabel
                sx={{
                  minWidth: MIN_WIDTH_SLIDER_LABEL,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {t("label_assessment")}
                {/* Make tooltip display line breaks */}
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {t(`explanation_${feedback?.feedbackType}_assessment`)}
                    </div>
                  }
                >
                  <SvgIcon fontSize="small" color="action" sx={{ mt: 0.5 }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              </FormLabel>
              <Controller
                control={control}
                name="assessment"
                render={({ field }) => (
                  <DMASlider
                    translationNS="stakeholder_feedback_dialog"
                    value={field.value}
                    onChange={(_, value) => field.onChange(value as number)}
                    aria-label={t("label_assessment")}
                    disabled={disabled}
                  />
                )}
              />
            </SliderContainer>
          )}

          {/* Feedback */}
          <Controller
            control={control}
            name="feedback"
            render={({ field }) => (
              <TextField
                {...field}
                label={t("label_feedback")}
                error={!!errors.feedback}
                helperText={errors.feedback?.message}
                multiline
                minRows={3}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={() => onClose(null)} disabled={disabled}>
          {isDirty || createMode ? t("button_cancel") : t("button_close")}
        </DialogCancelButton>
        <DialogSaveButton onClick={handleSubmit(handleEmit)} disabled={!isDirty || disabled}>
          {!createMode ? t("button_save") : t("button_create")}
        </DialogSaveButton>
      </DialogActions>
    </Dialog>
  );
};

interface IBaseProps {
  optOutReason: string | null;
}

interface IPropsOptOutOnly extends IBaseProps {
  mode: "opt-out-only";
}

interface IPropsWithMateriality extends IBaseProps {
  mode: "with-materiality";
  materiality: IDMACategoryWithEffectsAndChildren["materiality"];
  type: IStakeholderFeedback["feedbackType"];
}

type IStakeholderFeedbackDialogMaterialityInfoComponentProps =
  | IPropsOptOutOnly
  | IPropsWithMateriality;

export const StakeholderFeedbackDialogMaterialityInfoComponent: FC<
  IStakeholderFeedbackDialogMaterialityInfoComponentProps
> = (props) => {
  const { optOutReason, mode } = props;

  const materialityDegree =
    mode === "with-materiality"
      ? props.type === "material"
        ? props.materiality.materialityDegreeMaterial
        : props.materiality.materialityDegreeFinancial
      : null;

  const materiality =
    mode === "with-materiality"
      ? props.type === "material"
        ? props.materiality.materialityMaterial
        : props.materiality.materialityFinancial
      : null;

  const { t } = useTranslation("stakeholder_feedback_dialog");

  return (
    <Box pb={2} display="flex" flexDirection="row" gap={2}>
      {optOutReason !== null && (
        <Alert variant="standard" severity="info" sx={{ width: "100%" }}>
          <AlertTitle>{t("materiality_opt_out_title")}</AlertTitle>
          {optOutReason}
        </Alert>
      )}
      {optOutReason === null && (
        <>
          {/* TODO: account for monetary values once it's implemented: https://netcero.atlassian.net/browse/NC-469 */}
          <Tooltip title={t("materiality_tooltip_materiality_degree")}>
            <Typography variant="body1">
              {t("materiality_materiality_degree_label")}
              {": "}
              {DMAFormatUtilities.formatMaterialityDegree(materialityDegree)} /{" "}
              {DMAFormatUtilities.formatMaterialityDegree(5)}
            </Typography>
          </Tooltip>
          <Typography variant="body1">
            {t("materiality_material_label")}:{" "}
            {FormatUtilities.formatBoolean(materiality, t("material_yes"), t("material_no"))}
          </Typography>
        </>
      )}
    </Box>
  );
};
