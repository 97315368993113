/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * May only be specified if the IP has data quality.
 * @export
 * @enum {string}
 */

export const IDEOIPValueDataQuality = {
  Measured: "measured",
  Calculated: "calculated",
  Estimated: "estimated",
} as const;

export type IDEOIPValueDataQuality =
  (typeof IDEOIPValueDataQuality)[keyof typeof IDEOIPValueDataQuality];
