import { Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { useOrganizationsQuery } from "../../modules/organizations/organizations.queries";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";

interface IOrganizationsRedirectPageProps {}

export const OrganizationsRedirectPage: FC<IOrganizationsRedirectPageProps> = () => {
  const { t } = useTranslation("organizations_redirect_page");

  const organizationsQuery = useOrganizationsQuery();

  return (
    <QueryWrapper
      query={organizationsQuery}
      loadingOverride={() => <CenteredCircularProgress minHeight="calc(100vh - 64px)" />}
      build={(data) =>
        data.organizations.length > 0 ? (
          <Navigate to={`/organizations/${data.organizations[0].id}`} replace />
        ) : (
          <Typography variant="body1">{t("no_associated_organizations")}</Typography>
        )
      }
    />
  );
};
