import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

/**
 * Gets all organizations
 * @returns Query result for all organizations
 */
export function useOrganizationsQuery() {
  return useQuery({
    queryKey: ["organizations"],
    queryFn: () =>
      CoreApiService.OrganizationApi.getAllOrganizations()
        .then((req) => req())
        .then((res) => res.data),
  });
}
