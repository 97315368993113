import { FC, useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

interface IDrEggTableAutocompleteMultipleProps {
  label: string;
  autocompleteValues: string[];
  value: string[];
  onChange: (value: string[]) => void;
  onSave?: VoidFunction;
  disabled?: boolean;
}

export const DrEggTableAutocompleteMultiple: FC<IDrEggTableAutocompleteMultipleProps> = ({
  label,
  autocompleteValues,
  value,
  onChange,
  onSave,
  disabled,
}) => {
  const stillUnusedValues = useMemo(
    () => autocompleteValues.filter((v) => !value.includes(v)),
    [autocompleteValues, value],
  );

  return (
    <Autocomplete
      size="small"
      freeSolo
      multiple
      disableClearable
      autoSelect
      options={stillUnusedValues}
      value={value}
      onChange={(_, newValue, reason) => {
        onChange(newValue);
        if (reason === "removeOption") {
          onSave?.();
        }
      }}
      onBlur={onSave}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
      sx={{ minWidth: 200 }}
    />
  );
};
