/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * esrs:LayerInMitigationHierarchyForWaterAndMarineResourcesMember
 * @export
 * @enum {string}
 */

export const ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum = {
  AvoidanceOfUseOfWaterAndMarineResourcesMember:
    "esrs:AvoidanceOfUseOfWaterAndMarineResourcesMember",
  ReductionOfUseOfWaterAndMarineResourcesMember:
    "esrs:ReductionOfUseOfWaterAndMarineResourcesMember",
  ReclaimingAndReuseOfWaterMember: "esrs:ReclaimingAndReuseOfWaterMember",
  RestorationAndRegenerationOfAquaticEcosystemsAndWaterBodiesMember:
    "esrs:RestorationAndRegenerationOfAquaticEcosystemsAndWaterBodiesMember",
} as const;

export type ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum =
  (typeof ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum)[keyof typeof ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum];
