import { useCallback, useMemo, useState } from "react";
import { useObserveSizeWithState } from "./use-observe-size.hook";

interface ISize {
  width: number;
  height: number;
}

export const useObserveSizeState = () => {
  const [size, setSize] = useState<ISize>({ height: 0, width: 0 });

  const callback = useCallback((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    if (entries.length !== 1) {
      return;
    }
    const entry = entries[0];
    setSize({ height: entry.contentRect.height, width: entry.contentRect.width });
  }, []);

  const setElementRef = useObserveSizeWithState(callback);

  return useMemo(() => ({ size, setElementRef }), [setElementRef, size]);
};
