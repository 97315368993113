import {
  IDisclosureRequirementAssessment,
  IDisclosureRequirementForAssessment,
  IESRSTopicIdentifier,
} from "@netcero/netcero-core-api-client";
import { IDrAssessmentTableDataByTopic, IDrAssessmentUsedValues } from "./dr-assessment.types";
import { deduplicatePrimArray, generateLookUp } from "@netcero/netcero-common";

export class DrAssessmentUtilities {
  public static generateTableDrList(
    drsForAssessment: IDisclosureRequirementForAssessment[],
    assessments: IDisclosureRequirementAssessment[],
  ): IDrAssessmentTableDataByTopic {
    const result: IDrAssessmentTableDataByTopic = {} as IDrAssessmentTableDataByTopic;
    Object.values(IESRSTopicIdentifier).forEach((topic) => {
      result[topic] = [];
    });

    const assessmentLookup = generateLookUp(assessments, (a) => a.disclosureRequirementId);

    drsForAssessment.forEach((disclosureRequirement) => {
      result[disclosureRequirement.esrsTopicIdentifier].push({
        disclosureRequirement: disclosureRequirement,
        assessment: assessmentLookup[disclosureRequirement.disclosureId] ?? null,
      });
    });

    return result;
  }

  public static getUsedAssessmentValues(
    assessments: IDisclosureRequirementAssessment[],
  ): IDrAssessmentUsedValues {
    const people: string[] = [];
    const deos: string[] = [];
    const sources: string[] = [];

    assessments.forEach((assessment) => {
      if (assessment.responsiblePerson) {
        people.push(assessment.responsiblePerson);
      }
      people.push(...assessment.contributingPeople.filter((p) => p));
      deos.push(...assessment.organizationalUnits.filter((p) => p));
      sources.push(...assessment.sources.filter((p) => p));
    });

    return {
      people: deduplicatePrimArray(people),
      organizationalUnits: deduplicatePrimArray(deos),
      sources: deduplicatePrimArray(sources),
    };
  }
}
