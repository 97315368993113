import { Box, CircularProgress } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { DataEntryObjectValuesOverviewThgComponent } from "../../modules/data-entry-object-values/thg/data-entry-object-values-overview-thg.component";
import { useDataEntryObjectValuesQuery } from "../../modules/data-entry-object-values/queries/data-entry-object-values.queries";
import { useListValuesQuery } from "../../modules/data-entry-object-values/queries/list-values.queries";
import { useInputParameterRecordingStructureDetailsQuery } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useCurrentRecordingPeriod } from "../../modules/recording-periods/use-current-recording-period.hook";
import { useAppliedDistributionCriteriaQuery } from "../../modules/applied-distribution-criteria/applied-distribution-criteria.queries";
import { useDEOLookup } from "../../modules/data-entry-object-values/hooks/deo-lookup.hook";
import { DeoEsrsValuesOverviewComponent } from "../../modules/data-entry-object-values/esrs/deo-esrs-values-overview.component";
import { InputParameterRecordingStructuresUtilities } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.utilities";
import { useIntercomReferencesForEntityQuery } from "../../modules/intercom-references/intercom-references.queries";
import { IIntercomEntity } from "@netcero/netcero-core-api-client";
import {
  IIntercomReferencesContextState,
  IntercomReferencesProvider,
} from "../../modules/intercom-references/intercom-references.context";
import { useCombineQueryState } from "../../modules/common/hooks/combine-query-state.hook";

export const DataEntryObjectValueInputPage: FC = () => {
  const navigate = useNavigate();

  const { organizationId, recordingPeriodId, recordingStructureId, dataEntryObjectId } =
    useParams();

  const currentOrganization = useCurrentOrganization();
  const currentRecordingPeriod = useCurrentRecordingPeriod();

  // Setup Header & Sidebar

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization!.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${currentOrganization?.id}`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, currentOrganization, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  // Queries

  // Fetch structure + create lookup to easily reference DEOs by id
  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const lookup = useDEOLookup(organizationStructureQuery.data);

  const recordingStructureQuery = useInputParameterRecordingStructureDetailsQuery(
    organizationId!,
    recordingPeriodId!,
    recordingStructureId!,
  );

  const dataEntryObjectValuesQuery = useDataEntryObjectValuesQuery(
    organizationId!,
    recordingPeriodId!,
    dataEntryObjectId!,
    recordingStructureId!,
  );

  const listValuesQuery = useListValuesQuery(organizationId!);

  // Distribution Criteria
  const distributionCriteriaQuery = useAppliedDistributionCriteriaQuery(
    organizationId!,
    recordingPeriodId!,
  );

  // Handlers

  const handleChangeDataEntryObject = (newDataEntryObjectId: string) => {
    navigate(
      `/organizations/${organizationId}` +
        `/recording-periods/${recordingPeriodId}` +
        `/recording-structures/${recordingStructureId}` +
        `/data-entry-objects/${newDataEntryObjectId}`,
    );
  };

  // Intercom queries
  const intercomDRsQuery = useIntercomReferencesForEntityQuery(
    IIntercomEntity.DisclosureRequirement,
  );
  const intercomPoliciesQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Policy);
  const intercomActionsQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Action);
  const intercomTargetQuery = useIntercomReferencesForEntityQuery(IIntercomEntity.Target);

  const {
    isLoading,
    isError,
    error: queriesError,
  } = useCombineQueryState([
    organizationStructureQuery,
    recordingStructureQuery,
    dataEntryObjectValuesQuery,
    listValuesQuery,
    distributionCriteriaQuery,
    intercomDRsQuery,
    intercomPoliciesQuery,
    intercomActionsQuery,
    intercomTargetQuery,
  ]);

  // Intercom state
  const intercomState: IIntercomReferencesContextState = useMemo(() => {
    if (isLoading) {
      return {};
    }
    return {
      [IIntercomEntity.DisclosureRequirement]: intercomDRsQuery.data!,
      [IIntercomEntity.Policy]: intercomPoliciesQuery.data!,
      [IIntercomEntity.Action]: intercomActionsQuery.data!,
      [IIntercomEntity.Target]: intercomTargetQuery.data!,
    };
  }, [
    intercomActionsQuery.data,
    intercomDRsQuery.data,
    intercomPoliciesQuery.data,
    intercomTargetQuery.data,
    isLoading,
  ]);

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: "100vh" }}
    >
      <CircularProgress />
    </Box>
  ) : isError ? (
    <ErrorTextComponent error={queriesError!} />
  ) : (
    <OrganizationStructureDraftWrapper
      organizationId={organizationId!}
      recordingPeriodId={recordingPeriodId!}
      organizationStructure={organizationStructureQuery.data!}
      build={(organizationStructure) => (
        <Box pt={4} px={4}>
          {InputParameterRecordingStructuresUtilities.isTHGRecordingStructure(
            recordingStructureQuery.data!,
          ) && (
            <DataEntryObjectValuesOverviewThgComponent
              organization={currentOrganization!}
              recordingPeriod={currentRecordingPeriod!}
              recordingStructure={recordingStructureQuery.data!}
              organizationStructure={organizationStructure}
              dataEntryObject={lookup[dataEntryObjectId!]}
              dataEntryObjectInputParameters={dataEntryObjectValuesQuery.data!.inputParameters}
              availableDistributionCriteria={(distributionCriteriaQuery.data?.criteria ?? [])
                // ensure that only applied distribution criteria are shown
                .filter((dc) => dc.applied)}
              onChangeDataEntryObject={handleChangeDataEntryObject}
            />
          )}
          <IntercomReferencesProvider state={intercomState}>
            {InputParameterRecordingStructuresUtilities.isESRSRecordingStructure(
              recordingStructureQuery.data!,
            ) && (
              <DeoEsrsValuesOverviewComponent
                organization={currentOrganization!}
                recordingPeriod={currentRecordingPeriod!}
                recordingStructure={recordingStructureQuery.data!}
                organizationStructure={organizationStructure}
                dataEntryObject={lookup[dataEntryObjectId!]}
                dataEntryObjectInputParameters={dataEntryObjectValuesQuery.data!.inputParameters}
                availableDistributionCriteria={(distributionCriteriaQuery.data?.criteria ?? [])
                  // ensure that only applied distribution criteria are shown
                  .filter((dc) => dc.applied)}
                onChangeDataEntryObject={handleChangeDataEntryObject}
              />
            )}
          </IntercomReferencesProvider>
        </Box>
      )}
    />
  );
};
