/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface IInputParameterRecordingMetaData
 */
export interface IInputParameterRecordingMetaData {
  /**
   * Specifies how often a value can or should be acquired
   * @type {string}
   * @memberof IInputParameterRecordingMetaData
   */
  recordingInterval: IInputParameterRecordingMetaDataRecordingIntervalEnum;
}

export const IInputParameterRecordingMetaDataRecordingIntervalEnum = {
  Unspecified: "unspecified",
  SingleValuePerRecordingPeriod: "single_value_per_recording_period",
} as const;

export type IInputParameterRecordingMetaDataRecordingIntervalEnum =
  (typeof IInputParameterRecordingMetaDataRecordingIntervalEnum)[keyof typeof IInputParameterRecordingMetaDataRecordingIntervalEnum];
