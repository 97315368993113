/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * This is the API for the NetCero Core Service.
 *
 * The version of the OpenAPI document: 0.1-PRERELEASE
 * Contact: office@dectria.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IDisclosureRequirementAssessment } from "../models";
// @ts-ignore
import { IDisclosureRequirementAssessmentData } from "../models";
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from "../models";
// @ts-ignore
import { IGetAvailableDisclosureRequirementsForAssessment200Response } from "../models";
// @ts-ignore
import { IGetDisclosureRequirementAssessments200Response } from "../models";
// @ts-ignore
import { IUpdateDisclosureRequirementAssessment404Response } from "../models";
/**
 * DisclosureRequirementAssessmentsApi - axios parameter creator
 * @export
 */
export const DisclosureRequirementAssessmentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * This endpoint retrieves all available disclosure requirements for assessment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableDisclosureRequirementsForAssessment: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/disclosure-requirement-assessments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint retrieves all disclosure requirement assessments for the current organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclosureRequirementAssessments: async (
      organizationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getDisclosureRequirementAssessments", "organizationId", organizationId);
      const localVarPath =
        `/organizations/{organizationId}/disclosure-requirements/assessments`.replace(
          `{${"organizationId"}}`,
          encodeURIComponent(String(organizationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to update the assessment for the given disclosure requirement.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} disclosureRequirementId The id of the disclosure requirement to use.
     * @param {IDisclosureRequirementAssessmentData} [iDisclosureRequirementAssessmentData] Update Disclosure Requirement Assessment Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDisclosureRequirementAssessment: async (
      organizationId: string,
      disclosureRequirementId: string,
      iDisclosureRequirementAssessmentData?: IDisclosureRequirementAssessmentData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateDisclosureRequirementAssessment", "organizationId", organizationId);
      // verify required parameter 'disclosureRequirementId' is not null or undefined
      assertParamExists(
        "updateDisclosureRequirementAssessment",
        "disclosureRequirementId",
        disclosureRequirementId,
      );
      const localVarPath =
        `/organizations/{organizationId}/disclosure-requirements/{disclosureRequirementId}/assessments`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(
            `{${"disclosureRequirementId"}}`,
            encodeURIComponent(String(disclosureRequirementId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDisclosureRequirementAssessmentData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DisclosureRequirementAssessmentsApi - functional programming interface
 * @export
 */
export const DisclosureRequirementAssessmentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DisclosureRequirementAssessmentsApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint retrieves all available disclosure requirements for assessment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableDisclosureRequirementsForAssessment(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetAvailableDisclosureRequirementsForAssessment200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAvailableDisclosureRequirementsForAssessment(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint retrieves all disclosure requirement assessments for the current organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDisclosureRequirementAssessments(
      organizationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetDisclosureRequirementAssessments200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDisclosureRequirementAssessments(
        organizationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to update the assessment for the given disclosure requirement.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} disclosureRequirementId The id of the disclosure requirement to use.
     * @param {IDisclosureRequirementAssessmentData} [iDisclosureRequirementAssessmentData] Update Disclosure Requirement Assessment Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDisclosureRequirementAssessment(
      organizationId: string,
      disclosureRequirementId: string,
      iDisclosureRequirementAssessmentData?: IDisclosureRequirementAssessmentData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDisclosureRequirementAssessment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDisclosureRequirementAssessment(
          organizationId,
          disclosureRequirementId,
          iDisclosureRequirementAssessmentData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DisclosureRequirementAssessmentsApi - factory interface
 * @export
 */
export const DisclosureRequirementAssessmentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DisclosureRequirementAssessmentsApiFp(configuration);
  return {
    /**
     * This endpoint retrieves all available disclosure requirements for assessment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableDisclosureRequirementsForAssessment(
      options?: any,
    ): AxiosPromise<IGetAvailableDisclosureRequirementsForAssessment200Response> {
      return localVarFp
        .getAvailableDisclosureRequirementsForAssessment(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint retrieves all disclosure requirement assessments for the current organization.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDisclosureRequirementAssessments(
      organizationId: string,
      options?: any,
    ): AxiosPromise<IGetDisclosureRequirementAssessments200Response> {
      return localVarFp
        .getDisclosureRequirementAssessments(organizationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to update the assessment for the given disclosure requirement.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} disclosureRequirementId The id of the disclosure requirement to use.
     * @param {IDisclosureRequirementAssessmentData} [iDisclosureRequirementAssessmentData] Update Disclosure Requirement Assessment Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDisclosureRequirementAssessment(
      organizationId: string,
      disclosureRequirementId: string,
      iDisclosureRequirementAssessmentData?: IDisclosureRequirementAssessmentData,
      options?: any,
    ): AxiosPromise<IDisclosureRequirementAssessment> {
      return localVarFp
        .updateDisclosureRequirementAssessment(
          organizationId,
          disclosureRequirementId,
          iDisclosureRequirementAssessmentData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DisclosureRequirementAssessmentsApi - interface
 * @export
 * @interface DisclosureRequirementAssessmentsApi
 */
export interface DisclosureRequirementAssessmentsApiInterface {
  /**
   * This endpoint retrieves all available disclosure requirements for assessment.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DisclosureRequirementAssessmentsApiInterface
   */
  getAvailableDisclosureRequirementsForAssessment(
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetAvailableDisclosureRequirementsForAssessment200Response>;

  /**
   * This endpoint retrieves all disclosure requirement assessments for the current organization.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DisclosureRequirementAssessmentsApiInterface
   */
  getDisclosureRequirementAssessments(
    organizationId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetDisclosureRequirementAssessments200Response>;

  /**
   * This endpoint is used to update the assessment for the given disclosure requirement.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} disclosureRequirementId The id of the disclosure requirement to use.
   * @param {IDisclosureRequirementAssessmentData} [iDisclosureRequirementAssessmentData] Update Disclosure Requirement Assessment Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DisclosureRequirementAssessmentsApiInterface
   */
  updateDisclosureRequirementAssessment(
    organizationId: string,
    disclosureRequirementId: string,
    iDisclosureRequirementAssessmentData?: IDisclosureRequirementAssessmentData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDisclosureRequirementAssessment>;
}

/**
 * DisclosureRequirementAssessmentsApi - object-oriented interface
 * @export
 * @class DisclosureRequirementAssessmentsApi
 * @extends {BaseAPI}
 */
export class DisclosureRequirementAssessmentsApi
  extends BaseAPI
  implements DisclosureRequirementAssessmentsApiInterface
{
  /**
   * This endpoint retrieves all available disclosure requirements for assessment.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DisclosureRequirementAssessmentsApi
   */
  public getAvailableDisclosureRequirementsForAssessment(options?: AxiosRequestConfig) {
    return DisclosureRequirementAssessmentsApiFp(this.configuration)
      .getAvailableDisclosureRequirementsForAssessment(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint retrieves all disclosure requirement assessments for the current organization.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DisclosureRequirementAssessmentsApi
   */
  public getDisclosureRequirementAssessments(organizationId: string, options?: AxiosRequestConfig) {
    return DisclosureRequirementAssessmentsApiFp(this.configuration)
      .getDisclosureRequirementAssessments(organizationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to update the assessment for the given disclosure requirement.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} disclosureRequirementId The id of the disclosure requirement to use.
   * @param {IDisclosureRequirementAssessmentData} [iDisclosureRequirementAssessmentData] Update Disclosure Requirement Assessment Request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DisclosureRequirementAssessmentsApi
   */
  public updateDisclosureRequirementAssessment(
    organizationId: string,
    disclosureRequirementId: string,
    iDisclosureRequirementAssessmentData?: IDisclosureRequirementAssessmentData,
    options?: AxiosRequestConfig,
  ) {
    return DisclosureRequirementAssessmentsApiFp(this.configuration)
      .updateDisclosureRequirementAssessment(
        organizationId,
        disclosureRequirementId,
        iDisclosureRequirementAssessmentData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
