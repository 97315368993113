import { Dispatch, FC, useMemo } from "react";
import { SearchableMenu } from "../../common/components/searchable-menu.component";
import { ISource } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, MenuItem, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { createSourcesConfigurationPageLink } from "../../../pages/configuration/sources-configuration.page";
import { OpenInNewTabIcon } from "../../common/constants/tabler-icon.constants";
import { SourceCannotBeReferencedWarningIcon } from "./source-cannot-be-reference-warning.component";
import { SourcesVerification } from "@netcero/netcero-common";

function filterSources(search: string, sources: ISource[]) {
  return sources.filter((source) => source.name.toLowerCase().includes(search.toLowerCase()));
}

interface ISelectSourcesMenuProps {
  organizationId: string;
  show: boolean;
  anchorEl: HTMLElement | null;
  onClose: VoidFunction;
  sources: ISource[];
  selectedSourceIds: string[];
  onAddSource: Dispatch<ISource>;
  onlyAllowReferencableSources?: boolean;
  disabled?: boolean;
}

export const SelectSourceMenu: FC<ISelectSourcesMenuProps> = ({
  organizationId,
  show,
  anchorEl,
  onClose,
  sources,
  selectedSourceIds,
  onAddSource,
  onlyAllowReferencableSources,
  disabled,
}) => {
  const { t } = useTranslation("linked_sources");

  const availableSources = useMemo(
    () => sources.filter((source) => !selectedSourceIds.includes(source.id)),
    [sources, selectedSourceIds],
  );

  return (
    <SearchableMenu
      show={show}
      anchorEl={anchorEl}
      onClose={onClose}
      options={availableSources}
      filterOptions={filterSources}
      noOptionsPresentText={t("no_sources_available")}
      noOptionsFoundText={t("no_sources_found")}
      buildMenuItem={(source) => {
        const isSourceReferencable = SourcesVerification.canSourceBeReferenced(source);

        return (
          <Tooltip
            title={!isSourceReferencable ? t("warnings.cannot_be_linked", { ns: "sources" }) : ""}
            placement="right"
          >
            <span>
              <MenuItem
                key={source.id}
                onClick={() => onAddSource(source)}
                disabled={disabled || (onlyAllowReferencableSources && !isSourceReferencable)}
                sx={{ gap: 0.5 }}
              >
                <span style={{ flex: 1 }}>{source.name}</span>
                {!isSourceReferencable && (
                  <Box display="flex" alignItems="center">
                    <SourceCannotBeReferencedWarningIcon />
                  </Box>
                )}
                <IconButton
                  size="small"
                  component={RouterLink}
                  to={createSourcesConfigurationPageLink(organizationId, source.id).toString()}
                  target="_blank"
                  onClick={(event) => event.stopPropagation()}
                >
                  <OpenInNewTabIcon />
                </IconButton>
              </MenuItem>
            </span>
          </Tooltip>
        );
      }}
      bottomSection={
        <Box display="flex" justifyContent="center">
          <Button
            variant="text"
            component={RouterLink}
            to={createSourcesConfigurationPageLink(organizationId).toString()}
            target="_blank"
            sx={{ gap: 1 }}
          >
            {t("manage_sources", { ns: "sources" })}
            <OpenInNewTabIcon />
          </Button>
        </Box>
      }
    />
  );
};
